import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import { StepFooter } from './wizard.step-footer';
import { StepTitle } from './wizard.step-title';
import { Grid } from '../grid';
import { Grounder } from '../grounder';
import { getMotion } from '../motion';
import { css } from '@emotion/react';

export type WizardStep = {
  id?: string;
  title?: string;
  description?: string | JSX.Element;
  content: JSX.Element;
  titleRightActions?: React.ReactNode;
  rightFooterActions?: React.ReactNode;
  leftFooterActions?: React.ReactNode;
  hideBackButton?: boolean;
  onGoBack?: () => void;
  isNextStepDisabled?: boolean;
};

export type WizardProps = {
  steps: WizardStep[];
  currentStep: number;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  animationDuration?: number;
  colWidth?: number;
};

const Container = styled(Grid.Container)`
  padding-top: 24px;
  overflow-x: hidden;
  overflow-y: visible;
`;

const Content = styled.section`
  padding-top: 32px;
  padding-bottom: 72px;
  width: 100%;
`;

const currentStyles = css`
  pointer-events: auto;
  opacity: 1;
  overflow-y: visible;
  max-height: auto;
  transform: translateX(0%);
  transition-delay: 100ms;
`;

const hiddenStyles = css`
  pointer-events: none;
  opacity: 0;
  overflow-y: hidden;
  max-height: 0;
  transition-delay: 0ms;
`;

const nextStyles = css`
  transform: translateX(50%);
`;

const previousStyles = css`
  transform: translateX(-50%);
`;

const AnimationWrapper = styled.div<{
  duration: number;
  isCurrent: boolean;
  isNext?: boolean;
}>`
  width: 100%;
  transition: all ${({ duration }) => duration}ms;
  ${({ theme }) => getMotion(theme.tokens.motion.long.entry.value)}
  ${({ isCurrent }) => (isCurrent ? currentStyles : hiddenStyles)}
  ${({ isCurrent, isNext }) =>
    !isCurrent && (isNext ? nextStyles : previousStyles)}
`;

export function Wizard({
  steps,
  currentStep,
  handleNextStep,
  handlePrevStep,
  animationDuration,
  colWidth = 8,
}: WizardProps): JSX.Element {
  const [step, setStep] = useState(currentStep);
  const ANIMATION_DURATION = animationDuration || 400;

  const getOnBackButton = useCallback(
    (step: number): (() => void) | undefined => {
      const currentStep = steps[step];

      if (!currentStep) return undefined;
      if (currentStep.hideBackButton) return undefined;
      if (currentStep.onGoBack) return currentStep.onGoBack;
      if (step > 0) return handlePrevStep;

      return undefined;
    },
    [steps, handlePrevStep]
  );

  useEffect(() => {
    setStep(currentStep);

    const scrollTimeout = setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 100);

    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [currentStep, ANIMATION_DURATION]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
      }
      if (
        (event.key === 'Enter' || event.key === 'ArrowRight') &&
        !steps[step].isNextStepDisabled
      ) {
        handleNextStep();
      }
      if (event.key === 'ArrowLeft') {
        getOnBackButton(currentStep)?.();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleNextStep, currentStep, getOnBackButton, step, steps]);

  const showFooter =
    steps[step]?.rightFooterActions || steps[step]?.leftFooterActions;

  return (
    <Container>
      <Grid.Row justifyContent="center">
        <Grid.Col xs={colWidth}>
          {steps.map((stepContent, index) => (
            <AnimationWrapper
              duration={ANIMATION_DURATION}
              isCurrent={index === step}
              isNext={index > step}
              key={index}
            >
              <StepTitle
                title={stepContent.title}
                onGoBack={getOnBackButton(step)}
                description={stepContent.description}
                rightActions={stepContent.titleRightActions}
              />
              <Content>{stepContent.content}</Content>
              <Grounder />
            </AnimationWrapper>
          ))}

          {showFooter && (
            <StepFooter
              currentStep={step}
              rightFooterActions={steps[step]?.rightFooterActions}
              leftFooterActions={steps[step]?.leftFooterActions}
            />
          )}
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
}
