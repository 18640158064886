'use client';

import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import {
  ToolCard,
  ToolCardItem,
  Typography,
} from '@innovamat/glimmer-components';
import { useOrganizationQuery } from '@innovamat/glow-api-client';
import { Roles } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ToolsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

type ToolOptions = ToolCardItem & {
  hiddenRoles?: Roles[];
};

type Props = {
  roles: Roles[];
  organizationId: string;
};

type ShortCutEvents = 'em' | 'training' | 'courses' | 'app';

export function Shortcuts({ roles, organizationId }: Props): JSX.Element {
  const { data } = useOrganizationQuery({
    id: organizationId,
  });
  const { t } = useTranslation();
  const { setEventData } = useEventLogging();

  const handleSendEvent = (link: ShortCutEvents) => {
    setEventData('launcher.shortcut', { link });
  };

  const isPlatformEnabled = Boolean(data?.organization?.platformEnabled);

  const openInNewTab = (url: string): void => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const tools: ToolOptions[] = [
    {
      icon: 'TrainingIcon',
      title: t('shortcuts.training-tool.title'),
      description: t('shortcuts.training-tool.description'),
      color: 'blue',
      onClick: () => {
        handleSendEvent('training');
        openInNewTab(`/course-selector/learning`);
      },
    },
    {
      icon: 'CatalogIcon',
      title: t('shortcuts.catalog-tool.title'),
      description: t('shortcuts.catalog-tool.description'),
      color: 'orange',
      onClick: () => {
        handleSendEvent('courses');
        openInNewTab(`/course-selector/course`);
      },
    },
    {
      icon: 'AppletIcon',
      title: t('shortcuts.app-tool.title'),
      description: t('shortcuts.app-tool.description'),
      color: 'teal',
      rightIcon: 'OpenNewTabIcon',
      isDisabled: !isPlatformEnabled,
      onClick: () => {
        handleSendEvent('app');
        openInNewTab(t('shortcuts.app-tool.url'));
      },
    },
    {
      icon: 'ManipulativeEnvironmentIcon',
      title: t('shortcuts.manipulative-env-tool.title'),
      description: t('shortcuts.manipulative-env-tool.description'),
      color: 'purple',
      onClick: () => {
        handleSendEvent('em');
        openInNewTab(`/manipulative`);
      },
    },
  ];

  const filterTools = (tool: ToolOptions): boolean => {
    if (!tool.hiddenRoles) return true;
    if (tool.hiddenRoles.some((role) => roles.includes(role))) return false;
    return true;
  };

  return (
    <Container>
      <Typography.H3>{t('shortcuts.title')}</Typography.H3>
      <ToolsContainer>
        {tools.filter(filterTools).map((tool, id) => (
          <ToolCard key={id} {...tool} />
        ))}
      </ToolsContainer>
    </Container>
  );
}
