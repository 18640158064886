import { Typography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { AddClassroomDropdown } from '../../components/add-classroom-dropdown';
import { useUser } from '../../../user-management';
import { EmptyClassroom } from '@innovamat/glimmer-assets';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Text = styled.div`
  text-align: center;
  gap: 8px;
`;

const Description = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function SchoolClassroomsEmptyState() {
  const { user } = useUser();

  return (
    <Container>
      <EmptyClassroom />
      <Text>
        <Typography.H4>Esta centro aún no tiene clases creadas</Typography.H4>
        <Description>Añade clases usando el botón de abajo</Description>
      </Text>
      <AddClassroomDropdown
        disabled={!user}
        organizationId={user?.organizationId!}
        region={user?.region!}
        variant="accent"
        options={['add', 'import']} // TODO: add csv import
      />
    </Container>
  );
}
