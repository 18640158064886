import styled from '@emotion/styled';
import ContentBlock from '../../components/ContentBlock';
import DimensionsBlock from '../../components/DimensionsBlock';
import DisclaimerBlock from '../../components/DisclaimerBlock';
import SimpleBlock from '../../components/SimpleBlock';

import { Session } from '@innovamat/resource-viewer';
import { TFunction } from 'i18next';
import 'tippy.js/dist/tippy.css';
import { TitlePrintable } from '../../../components/TitlePrintable';
import { PrintableChallengesBlock } from '../../components/ChallengesBlock/PrintableChallengesBlock';
import { Standards } from '../../components/Standards';
import { DigitalGuideProvider } from '../../context';
import EarlyYearGuideProvider from './context/EarlyYearGuideProvider';
import {
  EARLY_YEAR_CONTENT_BLOCKS,
  EARLY_YEAR_CONTENT_BLOCKS_ICONS,
} from './EarlyYear.mappers';
import { ChallengesResponse } from './types';
import { EarlyYearDigitalGuide } from './types/earlyYearDigitalGuide';

const PrintableWrapper = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.dark['02']};
  margin: 0 auto;
  max-width: 1240px;

  @media print {
    padding: 0;
    border: none;
  }
`;

const Printable = styled.div`
  break-before: always;
  display: flex;
  flex-direction: column;
  width: 100%;

  .printableContent {
    column-count: 2;
    column-gap: 62px;

    @media print {
      column-fill: auto;
    }
  }
`;

const Summary = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  resource: Session;
  guide: EarlyYearDigitalGuide;
  challenges: ChallengesResponse | undefined;
  onEvent: (key: string, data: any) => void;
  t: TFunction<'translation', undefined, 'translation'>;
  translations: Record<string, string>;
};

export function EarlyYearPrintable(props: Props): JSX.Element {
  const { guide, t, translations, resource } = props;
  return (
    <DigitalGuideProvider t={t} translations={translations}>
      <EarlyYearGuideProvider {...props}>
        <PrintableWrapper>
          <Printable>
            <TitlePrintable
              title={resource.title}
              subtitle={t(guide.subtitle.body)}
            />
            <div className="printableContent">
              <Summary>
                <SimpleBlock
                  title={t('digitalguides.ey.summary')}
                  text={guide.summary}
                />
                <ContentBlock
                  isPrintable
                  contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
                  contentBlocks={guide.contentBlocks}
                  items={EARLY_YEAR_CONTENT_BLOCKS}
                  standardContent={resource.standardContent}
                />
                <Standards
                  tags={guide.standards}
                  standardContent={resource.standardContent}
                />
                <DimensionsBlock
                  isPrintable
                  dimensions={guide.dimensions}
                  title={t('digitalguides.processes')}
                />
                <DisclaimerBlock disclaimer={guide.dimensionsDisclaimer} />
              </Summary>
              <PrintableChallengesBlock
                challenges={guide.challenges}
                contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
              />
            </div>
          </Printable>
        </PrintableWrapper>
      </EarlyYearGuideProvider>
    </DigitalGuideProvider>
  );
}
