import { useEffect, useState } from 'react';
import { storage, unity } from '@innovamat/radiance-utils';
import UnityWebGL from '../UnityWebGL';
import useAppSelector from '../../hooks/useAppSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionActivityIsCompleted,
  actionGetRegionParameters,
  actionSetQuizNextActivity,
  actionSetUnityData,
  actionUpdateCoinsAndGems,
  actionUpdateUnityData,
} from '../../store/unityData/unityData.actions';
import { getTokenInfo, getUser, isRole } from '../../store/auth/selectors';
import { AppState } from '../../store/root/root.interfaces';
import { UnityDataState } from '../../store/unityData/unityData.interfaces';
import { useNavigate } from 'react-router-domv6';
import {
  API_PLATFORM,
  API_SOLVER,
} from '../../store/unityData/unityData.dataSource';
import {
  isElectronApp,
  KEY,
  triggerElectronChangeSceneEvent,
} from '../../utils/electronApp';
import OfflineMessage from '../OfflineMessage';
import {
  BUILD_NOT_FOUND,
  buildResolver,
  getCurrentCity,
  getParentSceneByChildStage,
  getProperHomePerSelectedStage,
  isApplet,
} from './utils';
import handleUnityEvent from './unityEventsHandler';
import { useUnityDataListenerForElectron } from '../../hooks/useUnityDataListenerForElectron';
import { BuildData } from './types';
import { setEventData } from '../../utils/setEventData';

function BuildWrapper(): JSX.Element {
  useUnityDataListenerForElectron();
  const user = useAppSelector(getUser);
  const tokenValues = useAppSelector(getTokenInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    unityData,
    unityData: {
      sceneName,
      selectedStage,
      Students,
      User,
      bridgeIsReady,
      packOrVariation,
      PlatformUrl,
      quizNextActivity,
      biomeId,
    },
  } = useSelector(({ uData }: AppState) => ({
    unityData: uData,
  }));
  const [buildData, setBuildData] = useState<BuildData | null>(null);
  const { isParent, isStudentJunior } = useAppSelector(isRole);
  const url = new URL(window.location.href);
  const urlSceneName = url.searchParams.get('sceneName');
  const [previousScene, setPreviousScene] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [iteration, setIteration] = useState(0);

  const storedSelectedStage = window.localStorage.getItem(
    'storedSelectedStage'
  );

  const setInitialValues = (): UnityDataState => {
    const currentStage =
      storedSelectedStage !== null ? storedSelectedStage : selectedStage;

    let scene = urlSceneName || getProperHomePerSelectedStage(currentStage);
    if (isParent) scene = getParentSceneByChildStage(Students, User);
    if (isStudentJunior) scene = getCurrentCity();
    return {
      Tokens: {
        AccessToken: tokenValues.access_token,
        RefreshToken: tokenValues.refresh_token,
        IdToken: tokenValues.id_token,
        clientID: process.env.NX_USERS_CLIENT_ID,
      },
      language: unity.getUnityLanguage(user?.language || 'es'),
      sceneName: scene,
      SceneName: scene,
      bridgeIsReady: false,
      quizNextActivity: false,
      selectedStage: currentStage,
      PlatformUrl: API_PLATFORM,
      SolverUrl: API_SOLVER,
      biomeId: 0,
    };
  };

  useEffect(() => {
    if (bridgeIsReady) {
      // Delay to prevent screen flash between scenes
      setTimeout(() => setShowLoader(false), 150);
    } else {
      setShowLoader(true);
    }
  }, [bridgeIsReady]);

  useEffect(() => {
    // Access Token Refreshed
    const newTokens = {
      Tokens: {
        AccessToken: tokenValues.access_token,
        RefreshToken: tokenValues.refresh_token,
        IdToken: tokenValues.id_token,
        clientID: process.env.NX_USERS_CLIENT_ID,
      },
    };
    dispatch(actionUpdateUnityData({ ...newTokens }));
  }, [tokenValues]);

  useEffect(() => {
    // Electron specific code
    if (previousScene !== '' && isElectronApp()) {
      triggerElectronChangeSceneEvent(sceneName!);
      return;
    }

    const getDataInputs = (): void => {
      if (!sceneName && !unityData.Tokens.AccessToken) {
        dispatch(actionSetUnityData(setInitialValues()));
      }
    };
    getDataInputs();

    const setConfig = async (): Promise<void> => {
      if (isApplet(sceneName) && User && PlatformUrl) {
        dispatch(actionUpdateCoinsAndGems(User.id, PlatformUrl));
      }
      const resolvedBuildData: BuildData = await buildResolver(
        sceneName as string,
        selectedStage
      );

      if (resolvedBuildData.buildPath === BUILD_NOT_FOUND) {
        dispatch(actionActivityIsCompleted());
      } else {
        setIteration(iteration + 1);
        setBuildData(resolvedBuildData);
      }
    };

    if (sceneName) {
      if (sceneName === 'Innovamat_Login') {
        if (isElectronApp()) window.localStorage.removeItem(KEY);
        window.localStorage.removeItem('storedSelectedStage');
        navigate('/');
        return;
      }

      if (sceneName === 'ErrorScene_Innovamat') {
        navigate('/error');
        return;
      }
      if (
        sceneName === 'AppStartLoadingInnovamat' &&
        (isParent || isStudentJunior)
      ) {
        window.sessionStorage.clear();
        storage.clear();
        window.location.href = '/';
      }
      setConfig();
      setPreviousScene(sceneName);
      if (quizNextActivity) dispatch(actionSetQuizNextActivity(false));
    }
  }, [sceneName, packOrVariation, quizNextActivity]);

  useEffect(() => {
    if (user) {
      dispatch(
        actionUpdateUnityData({
          language: unity.getUnityLanguage(user?.language),
        })
      );
    }
  }, [user?.language]);

  useEffect(() => {
    if (isStudentJunior && sceneName) {
      const scene = getCurrentCity();
      if (sceneName !== scene) {
        dispatch(
          actionUpdateUnityData({
            sceneName: scene,
            SceneName: scene,
            bridgeIsReady: false,
          })
        );
      }
    }
  }, [biomeId]);

  useEffect(() => {
    if (isParent && Students && !isApplet(sceneName)) {
      const scene =
        sceneName === 'ScrollInfantil' ||
        sceneName === 'District_Central' ||
        sceneName === 'HomeGreen' ||
        sceneName === 'District_Ice'
          ? getParentSceneByChildStage(Students, User)
          : sceneName || getParentSceneByChildStage(Students, User);
      if (sceneName !== scene) {
        dispatch(
          actionUpdateUnityData({
            sceneName: scene,
            SceneName: scene,
            bridgeIsReady: false,
          })
        );
      }
    }
  }, [Students]);

  useEffect(() => {
    // When changing between users with same biome (so same scene), it allows to change user
    if (User) setIteration(iteration + 1);
  }, [User]);

  useEffect(() => {
    if (user && storedSelectedStage) {
      dispatch(actionGetRegionParameters(user!, storedSelectedStage));
    }
  }, [user, storedSelectedStage]);

  useEffect(() => {
    if (User?.id) {
      setEventData('webapp_student_access', {
        student_id: User.id,
      });
    }
  }, [User?.id]);

  return (
    <div>
      {sceneName && buildData && (
        <UnityWebGL
          unityDataInput={unityData}
          buildData={buildData}
          key={!isElectronApp() ? buildData.buildPath + iteration : 'electron'}
          bridgeFunction={(eventMessage: MessageEvent) => {
            handleUnityEvent(dispatch)(eventMessage, {
              version: buildData.buildPath,
              componentKey: buildData.buildPath + iteration,
            });
          }}
        />
      )}
      <OfflineMessage />
    </div>
  );
}

export default BuildWrapper;
