import styled from '@emotion/styled';
import { useState } from 'react';
import { IconButton } from '../IconButton';

export type PasswordProps = {
  value: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PasswordField = styled.input`
  border: none;
`;

export function Password({ value }: PasswordProps): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <PasswordField
        type={showPassword ? 'text' : 'password'}
        value={value}
        readOnly
        size={value.length}
      />
      <IconButton
        onClick={toggleShowPassword}
        icon={showPassword ? 'ViewOffIcon' : 'ViewIcon'}
        size="S"
      />
    </Container>
  );
}
