import { css } from '@emotion/react';
import type { SerializedStyles, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Icon,
  IconButton,
  useCheckOverflow,
} from '@innovamat/glimmer-components';
import { IconType } from '@innovamat/glimmer-icons';
import { ImageTooltip } from './ImageTooltip';
import { useCallback, useState } from 'react';
import { PrintableBody2 } from '../../printable-styles/printable-typography';

const onHover = (theme: Theme): SerializedStyles => {
  return css`
    &:hover {
      div {
        color: ${theme.tokens.color.alias.cm.text.text.value};
        cursor: pointer;

        svg,
        path {
          fill: ${theme.tokens.color.alias.cm.text.text.value};
        }
      }
    }
  `;
};

const Wrapper = styled.div<{
  isDisabled?: boolean;
  isDownloadable?: boolean;
  hasTooltip?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ hasTooltip }) => (hasTooltip ? 'pointer' : 'default')};

  // Workaround to avoid the player link styles to be applied
  :has(span[done='true']) {
    span {
      color: inherit;
      &:hover {
        color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
      }
    }
  }

  ${({ hasTooltip, theme }) =>
    hasTooltip &&
    css`
      ${onHover(theme)}
    `}

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    @media print {
      align-items: flex-start;
      gap: 4px;
    }

    :has(a) {
      ${({ theme }) => onHover(theme)}
    }

    ${({ isDownloadable, theme }) =>
      isDownloadable &&
      css`
        ${onHover(theme)}
      `}

    @media print {
      display: ${({ isDownloadable }) => (isDownloadable ? 'none' : 'flex')};
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `};
`;

const Text = styled(PrintableBody2)<{
  isDownloadable?: boolean;
  hasTooltip?: boolean;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media print {
    white-space: normal;
  }

  > div {
    display: inline;

    * {
      display: inline;
    }
  }

  > span {
    display: inline;

    * {
      display: inline;
    }
  }

  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  @media print {
    color: #000000;
  }

  ${({ isDownloadable, theme }) =>
    isDownloadable &&
    css`
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: ${theme.tokens.color.alias.cm.text['text-subtle']
        .value};
      @media print {
        text-decoration: none;
        color: #000000;
      }
    `}

  a {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
    color: inherit;

    @media print {
      text-decoration: none;
      color: #000000;
    }
  }
`;

const TextWrapper = styled.div<{ isDownloadable?: boolean }>`
  width: 100%;
  overflow: hidden;

  > span {
    overflow: hidden;
  }
`;

const IconButtonContainer = styled.div`
  @media print {
    display: none !important;
  }
`;

const StyledIcon = styled(Icon)`
  @media print {
    width: 14px;
    height: 14px;
  }
`;

type ResourceProps = {
  children: React.ReactNode;
  icon?: IconType;
  isDownloadable?: boolean;
  isDisabled?: boolean;
  tooltip?: { img?: string; text?: string };
  onDownload?: () => void;
  onOpen?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export function Resource({
  children,
  icon,
  isDownloadable,
  onDownload,
  onOpen,
  isDisabled,
  tooltip,
  ...rest
}: ResourceProps): JSX.Element {
  const [textWrapperRef, setTextWrapperRef] = useState<HTMLElement | null>(
    null
  );

  const onTextWrapperRef = useCallback((ref: HTMLElement | null) => {
    setTextWrapperRef(ref);
  }, []);

  const { hasOverflow } = useCheckOverflow({
    text: textWrapperRef?.querySelector('p'),
    container: textWrapperRef,
    dependencies: [textWrapperRef],
  });

  const hasTooltip = hasOverflow || !!tooltip?.img;

  return (
    <Wrapper
      isDisabled={isDisabled}
      isDownloadable={isDownloadable}
      hasTooltip={hasTooltip}
      {...rest}
    >
      <TextWrapper isDownloadable={isDownloadable} ref={onTextWrapperRef}>
        {icon && <StyledIcon size="M" icon={icon} />}
        <ImageTooltip
          image={tooltip?.img}
          placement="left"
          text={hasTooltip ? tooltip?.text : undefined}
        >
          <Text
            onClick={onOpen}
            isDownloadable={isDownloadable}
            hasTooltip={hasTooltip}
          >
            {children}
          </Text>
        </ImageTooltip>
      </TextWrapper>

      {isDownloadable && (
        <IconButtonContainer>
          <IconButton
            onClick={() => onDownload?.()}
            size="S"
            icon="DownloadIcon"
            state={isDisabled ? 'disabled' : undefined}
          />
        </IconButtonContainer>
      )}
    </Wrapper>
  );
}
