import { AnyAction, Dispatch } from 'redux';
import authActions from '../../store/auth/actions';
import { setEventData } from '../../utils/setEventData';
import { manageUnityError } from '@innovamat/unity-toolkit-errors';
import { store } from '../../store/store';
import {
  actionActivityIsCompleted,
  actionSetBridgeIsReady,
  actionSetPreviousScene,
  actionSetUnityData,
  actionSkipActivity,
  actionUpdateUnityData,
} from '../../store/unityData/unityData.actions';
import { isApplet } from './utils';
import { authAxiosInstance } from '@innovamat/ga-features';
import { storage } from '@innovamat/radiance-utils';

declare global {
  interface Window {
    newrelic: any;
  }
}

let metaDataInfo = { isCompleted: false };

function healthEvent(healthEvent: string): void {
  const {
    uData: { sceneName, packOrVariation, previousSceneName },
    auth: {
      user: { uid },
    },
  } = store.getState();

  // Try to detect when applet is not loaded from Applet Selector
  const isSession =
    isApplet(sceneName) &&
    previousSceneName !== 'ActivityManager' &&
    sceneName !== 'ManipulativeZone';

  if (isSession) {
    window.newrelic?.addPageAction(healthEvent, {
      origin: 'webapp',
      sceneName,
      packOrVariation,
      stage: 'PRIMARIA',
      userId: uid,
    });
  }
}

export function manageError(errorMessage: string, metaData: any = null): void {
  if (
    typeof errorMessage === 'string' &&
    (errorMessage.includes('Exception') || errorMessage.includes('exception'))
  ) {
    const {
      uData: { sceneName, packOrVariation },
      auth: {
        user: { school, uid },
      },
    } = store.getState();

    manageUnityError({
      setEventData,
      axiosInstance: authAxiosInstance,
      apolloServer: window.__GA_CONFIG__.apolloServer!,
      version: metaData?.version,
      origin: 'webapp',
      error: {
        packOrVariation,
        sceneName,
        stage: 'PRIMARIA',
        errorMessage,
        metaDataInfo,
        school,
        uid,
        metaData,
      },
    });
  }
}

export function manageErrorStopper(errorMessage: string): void {
  const {
    uData: { sceneName, packOrVariation, Account, Students },
    auth: {
      deviceCode,
      user: { school, uid },
    },
  } = store.getState();

  const isConnectionError = !Account?.id && !Students;

  if (isConnectionError) {
    window.newrelic?.addPageAction('webapp_connection_error', {
      origin: 'webapp',
      sceneToLoad: sceneName,
      packOrVariationToLoad: packOrVariation,
      schoolId: school,
      userId: uid,
      deviceId: deviceCode,
    });
  } else {
    manageUnityError({
      setEventData,
      axiosInstance: authAxiosInstance,
      apolloServer: window.__GA_CONFIG__.apolloServer!,
      origin: 'webapp',
      error: {
        packOrVariation,
        sceneName,
        stage: 'PRIMARIA',
        errorMessage,
        errorType: 'Error-stopper',
        metaDataInfo,
        school,
        uid,
      },
    });
  }
}

function setLoadTime(loadingTimeData: any): void {
  const {
    uData: { sceneName, packOrVariation, startLoadingTime },
    auth: {
      deviceCode,
      user: { school, uid },
    },
  } = store.getState();
  const endTime = Date.now();
  const loadingTime = endTime - startLoadingTime.time;
  const {
    sendReadyToBrowser,
    unityBuildsDownloaded,
    decompressionFinished,
    loaderJSDownloadedStartInstance,
    initUnity,
    startLoadingTime: startLoadingTimeSent,
    estimatedConnection,
    fetchCacheStatus,
  } = loadingTimeData;

  const decompressionTime = decompressionFinished ?? sendReadyToBrowser;

  const dataObject = {
    origin: 'web-app',
    sceneToLoad: sceneName,
    packOrVariationToLoad: packOrVariation,
    fromScene: startLoadingTime.fromScene,
    loadingTime,
    loadingTimeMismatch: startLoadingTimeSent.time !== startLoadingTime.time,
    sendReadyToBrowser: sendReadyToBrowser - decompressionTime,
    sendReadyToBrowserTimeStamp: sendReadyToBrowser,
    unityBuildsDownloaded:
      unityBuildsDownloaded - loaderJSDownloadedStartInstance,
    unityBuildsDownloadedTimeStamp: unityBuildsDownloaded,
    loaderJSDownloadedStartInstance:
      loaderJSDownloadedStartInstance - initUnity,
    loaderJSDownloadedStartInstanceTimeStamp: loaderJSDownloadedStartInstance,
    initUnity: initUnity - startLoadingTimeSent.time,
    initUnityTimeStamp: initUnity,
    schoolId: school,
    userId: uid,
    deviceId: deviceCode,
    estimatedBandWithMbps: estimatedConnection?.estimatedBandWithMbps,
    estimatedSpeed: estimatedConnection?.estimatedSpeed,
    fetchCacheStatus,
    decompressionTime: decompressionTime - unityBuildsDownloaded,
  };

  if (
    typeof loadingTime === 'number' &&
    typeof endTime === 'number' &&
    typeof startLoadingTime.time === 'number'
  ) {
    // Send Data to NewRelic
    window.newrelic?.addPageAction('webapp_applet_loadingtime', dataObject);
    // Send Data to Logging Service
    setEventData('webapp_applet_loadingtime', dataObject);
  }
}

function legacyReducer(eventData: any, dispatch: Dispatch<AnyAction>): void {
  const {
    uData: { sceneName, packOrVariation },
  } = store.getState();

  window.newrelic?.addPageAction('check_use_legacy_reducer', {
    origin: 'webapp',
    sceneToLoad: sceneName,
    packOrVariationToLoad: packOrVariation,
  });

  if (eventData === 'BridgeIsReady') dispatch(actionSetBridgeIsReady(true));

  if (eventData === 'ActivityIsCompleted') {
    metaDataInfo = { isCompleted: true };
    dispatch(actionActivityIsCompleted());
  }

  manageError(eventData);
}

function lastestReducer(
  eventData: any,
  dispatch: Dispatch<AnyAction>,
  metaData: any = null
): void {
  const {
    auth: { authValues, user },
    uData,
  } = store.getState();
  switch (eventData.eventId) {
    case 'BridgeIsReady':
    case 'SendReadyToBrowser':
      setLoadTime(eventData.loadingTimeData);
      healthEvent('health_activityIsReady');
      dispatch(actionSetBridgeIsReady(true));
      break;
    case 'SendData':
      dispatch(actionSetUnityData(eventData.arguments));
      break;
    case 'GetBiome':
    case 'UpdateCity':
      dispatch(actionUpdateUnityData({ biomeId: eventData.arguments }));
      break;
    case 'ActivityExceptionCatch':
      const { sessionEntity, sessionType, uuid } = uData;
      if (sessionEntity?.activities) {
        dispatch(
          actionSkipActivity(
            uuid,
            sessionType,
            sessionEntity?.activities[0].codename
          )
        );
      }
      dispatch(actionActivityIsCompleted());
      break;
    case 'UpdateTokens':
      const { AccessToken, RefreshToken, IdToken } = eventData.arguments;
      const tokenData = {
        token_type: authValues.token_type,
        expires_in: authValues.expires_in,
        id_token: IdToken,
        access_token: AccessToken,
        refresh_token: RefreshToken,
      };
      storage.tokenInfo.set(tokenData);
      dispatch(authActions.refreshToken(tokenData));
      break;
    case 'SendLoadBuild':
      dispatch(
        actionSetUnityData({ sceneName: eventData.arguments.sceneName })
      );
      break;
    case 'SendCompleteActivityEvent':
      healthEvent('health_activityIsCompleted');
      dispatch(actionActivityIsCompleted(eventData.arguments));
      break;
    case 'SendCloseAppToBrowser':
      healthEvent('health_activityIsClosed');
      dispatch(actionSetPreviousScene());
      break;
    case 'Error':
      manageError(eventData.arguments[0], metaData);
      break;
    case 'Error-stopper':
      manageErrorStopper(eventData.arguments);
      dispatch(
        actionUpdateUnityData({
          sceneName: 'ErrorScene_Innovamat',
          SceneName: 'ErrorScene_Innovamat',
        })
      );
      break;
    default:
      break;
  }
}

export default function unityEventsHandler(
  dispatch: Dispatch<AnyAction>
): (eventMessage: MessageEvent, metaData?: any) => void {
  return (eventMessage: MessageEvent, metaData: any = null) => {
    const { data } = eventMessage;

    const isLegacyMessage = data.eventId === null || data.eventId === undefined;

    if (!isLegacyMessage) lastestReducer(data, dispatch, metaData);
    else if (isLegacyMessage) {
      if (!(data.source !== undefined)) {
        legacyReducer(data, dispatch);
      }
    }
  };
}
