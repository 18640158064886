import { useEventListener } from '@innovamat/hooks';
import { useLayoutEffect, useState } from 'react';

export function useIsExpandable(
  hasPreview: boolean,
  expanded: boolean,
  contentRef: React.RefObject<HTMLDivElement>,
  previewHeight: number
) {
  const [isExpandable, setIsExpandable] = useState(false);

  const handleIsExpandable = () => {
    if (!hasPreview) return setIsExpandable(true);
    if (!expanded && hasPreview && !!contentRef) {
      const contentWidth =
        contentRef.current!.querySelector('p')?.querySelector('p')
          ?.scrollWidth ||
        contentRef.current!.querySelector('span')?.scrollWidth;
      const divWidth = contentRef.current!.clientWidth;
      const divHeight = contentRef.current!.clientHeight;

      setIsExpandable(
        contentWidth! >= divWidth! || divHeight! > previewHeight!
      );
    }
  };

  useEventListener('resize', handleIsExpandable);

  useLayoutEffect(() => {
    handleIsExpandable();
  }, [previewHeight]);

  const hasImage = !!contentRef?.current?.querySelector('.mediaContainer');
  if (hasImage) return true;

  return isExpandable;
}
