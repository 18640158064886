export const replaceLatex = (text: string | undefined): string => {
  if (!text) return '';

  const regex = new RegExp(/##(.*?)##/, 'gm');
  let content = text;
  const expressions = content.match(regex);

  if (!expressions) return content;

  const latexIds: Record<string, string> = {};
  for (let index = 0; index < expressions.length; index++) {
    const expression = expressions[index];

    const cleanedExpression = expression.split('##').join('');

    if (!cleanedExpression) return '';

    const latexId = `latex-${index}`;
    latexIds[latexId] = cleanedExpression;
    const span = `<latexcomponent label="${cleanedExpression}"></latexcomponent>`;
    content = content.replace(expression, span);
  }

  return content;
};
