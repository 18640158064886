import { useRef } from 'react';
import {
  Dropdown,
  IconButton,
  State,
  usePortal,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { IconType } from '@innovamat/glimmer-icons';

const ActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  pointer-events: none;
`;

type ActionsCellProps = {
  dropdownId: string;
  items: Array<CreateItem>;
};

type CreateItem = {
  text: string;
  icon: IconType;
  state: State;
  onSelectItem: () => void;
};

function ActionsDropdown({ dropdownId, items }: ActionsCellProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  const { createPortal } = usePortal({
    containerRef: containerRef,
    rootElement: document.body,
    zIndex: 30,
  });

  return (
    <ActionsContainer className="action-cell">
      <Dropdown closeOnSelectItem>
        <div ref={containerRef}>
          <Dropdown.Toggle dataTestId={dropdownId}>
            <IconButton icon="OptionsIcon" size="L" aria-label="open menu" />
          </Dropdown.Toggle>
        </div>
        {createPortal(
          <Dropdown.Content position="bottomRight">
            {items.map(({ text, icon, state, onSelectItem }) => (
              <Dropdown.Item
                aria-label={text}
                icon={icon}
                state={state}
                onSelectItem={onSelectItem}
              >
                {text}
              </Dropdown.Item>
            ))}
          </Dropdown.Content>
        )}
      </Dropdown>
    </ActionsContainer>
  );
}

export { ActionsDropdown };
export type { CreateItem };
