import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { getElevation } from '../../elevation';
import type { ButtonCircleProps } from './button-circle';

const ButtonWrapper = styled.button<ButtonCircleProps>(({ theme }) => {
  const colorStateLayer = theme.tokens.color.specific['state-layer'];

  return css`
    ${getElevation(theme, 'elevation 2')}
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6.25rem;
    border: none;
    cursor: pointer;
    outline: none;
    color: ${theme.tokens.color.alias.cm.icon['icon-inverted'].value};
    background-color: ${theme.tokens.color.alias.cm.bg['bg-accent-inverted']
      .value};

    &:hover:enabled .button-stateLayer {
      background-color: ${colorStateLayer['state-hover-darker'].value};
    }
    &:active:enabled .button-stateLayer {
      background-color: ${colorStateLayer['state-press-darker'].value};
    }
    &:disabled {
      cursor: auto;
      background-color: ${theme.tokens.color.alias.cm.bg['bg-disabled'].value};

      svg,
      path,
      rect {
        fill: ${theme.tokens.color.alias.cm.icon['icon-disabled'].value};
      }
    }
    svg {
      position: relative;
    }

    svg,
    path,
    rect {
      fill: ${theme.tokens.color.alias.cm.icon['icon-inverted'].value};
    }
  `;
});

export { ButtonWrapper };
