import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ErrorPage } from '@innovamat/ga-features';
import {
  Grounder,
  HeadingSection,
  Input,
  Loader,
} from '@innovamat/glimmer-components';

import { FamilyPaymentEmptyState } from './components/family-payments-empty-state';
import { FamilyPaymentsTable } from './components/family-payments-table';
import { FamilyPaymentsTableSkeleton } from './components/family-payments-table-skeleton';

import { useInfiniteFamilyPayments } from './hooks/use-infinite-family-payments';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const FamilyPayments = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    familyPayments,
    isLoading,
    isError,
    isFetchedAfterMount,
    isFetchingNextPage,
  } = useInfiniteFamilyPayments();

  const renderContent = (): JSX.Element => {
    if (isError) {
      return <ErrorPage errorType="" />;
    }

    if (isLoading || !isFetchedAfterMount) {
      return <FamilyPaymentsTableSkeleton />;
    }

    if (!familyPayments.length) {
      return <FamilyPaymentEmptyState />;
    }

    return <FamilyPaymentsTable familyPayments={familyPayments} />;
  };

  return (
    <>
      <HeadingSection
        title={t('familyPayments.title')}
        // actions={<Input disabled />}
      />

      {renderContent()}

      {isFetchingNextPage && (
        <LoaderContainer>
          <Loader size="md" />
        </LoaderContainer>
      )}

      <Grounder />
    </>
  );
};

export { FamilyPayments };
