import {
  Button,
  Column,
  TableComponent,
  Typography,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { ProgressBar } from './progress-bar';
import { Trans, useTranslation } from 'react-i18next';
import { useReadCsvFile } from '../hooks/use-read-csv-file';
import { useCheckStudentRow } from '../hooks/use-check-student-row';
import { useCourses } from '@innovamat/glow-api-client';
import { useImportCSV } from '../providers';
import { ERRORS, StudentValidation } from '../types';
import { useEffect, useState } from 'react';
import { ConfirmValidationModal } from './confirm-validation-modal';
import { FooterSticky } from './footer-sticky';
import { ImportErrorPage } from './import-error-page';
import { RevalidateButton } from './revalidate-button';

const Title = styled(Typography.Subtitle1)<{ hasError: boolean }>`
  color: ${({ theme, hasError }) =>
    hasError
      ? theme.tokens.color.alias.cm.text['text-error'].value
      : 'inherit'};
`;

type Props = {
  csvFile: File;
  onUploadFile: () => void;
  onGoBack: () => void;
};

const ErrorText = styled('span')`
  display: inline;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text['text-error'].value};
`;

function StudentsValidation({
  csvFile,
  onUploadFile,
  onGoBack,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { csvData } = useReadCsvFile({ csvFile });
  const { organizationId, region } = useImportCSV();
  const [showModal, setShowModal] = useState(false);

  const { courses } = useCourses({
    organizationId,
    regionCode: region,
  });

  const { students, onClear, csvHasErrors, setStudents } = useCheckStudentRow({
    csvData,
  });

  useEffect(() => {
    return () => {
      onClear();
    };
  }, []);

  const canContinue = students.every(
    (student) => student?.errorType !== ERRORS.NOT_VALIDATED
  );

  const columns: Column<StudentValidation>[] = [
    {
      id: 'rowNumber',
      subLabel: t('students.import.column.row-number'),
      width: 5,
      render: (_, { rowNumber }) => {
        return <Typography.Body2>{rowNumber}</Typography.Body2>;
      },
    },
    {
      id: 'studentName',
      subLabel: t('students.import.column.studentName'),
      width: 10,
      render: (_, { studentName, errorType }) => {
        return (
          <Title hasError={errorType === ERRORS.NOT_VALIDATED}>
            {studentName}
          </Title>
        );
      },
    },
    {
      id: 'sisId',
      subLabel: t('students.import.column.sis-id'),
      width: 10,
      render: (_, { sisId }) => {
        return <Typography.Body2>{sisId}</Typography.Body2>;
      },
    },
    {
      id: 'errorType',
      subLabel: t('students.import.column.error-type'),
      width: 10,
      render: (_, { errorType }) => {
        return (
          <Typography.Body2>
            {t(`students.import.error-type.${errorType}`)}
          </Typography.Body2>
        );
      },
    },
    {
      id: 'overwrites',
      subLabel: t('students.import.column.overwrites'),
      width: 100,
      render: (_, { overwrites = {}, errorType, sisId: currentSisId }) => {
        if (!overwrites && errorType === ERRORS.NOT_VALIDATED) {
          return (
            <RevalidateButton sisId={currentSisId} setStudents={setStudents} />
          );
        }

        if (!overwrites) return null;

        const { firstName, lastName, sisId, classroom, courseOrder } =
          overwrites;

        const courseName = courses.find(
          (course) => course.order === courseOrder
        )?.name;

        return (
          <Typography.Body2>
            <Trans
              i18nKey="students.import.table.overwrites"
              components={{
                error: <ErrorText />,
              }}
              values={{
                firstName,
                lastName,
                sisId,
                classroom,
                course: courseName,
              }}
            />
          </Typography.Body2>
        );
      },
    },
  ];

  const handleContinue = (): void => {
    setShowModal(true);
  };

  const numberOfStudents = students.length;
  const totalOfStudents = csvData.length - 1;
  const percentage = Math.round((numberOfStudents / totalOfStudents) * 100);

  const rows = students.filter(Boolean) as StudentValidation[];

  const processFinished = numberOfStudents === totalOfStudents;

  const processFinishedWithErrors = processFinished && rows.length > 0;
  const processFinishedWithoutErrors = processFinished && rows.length === 0;

  useEffect(() => {
    if (processFinishedWithoutErrors) {
      onUploadFile();
    }
  }, [onUploadFile, processFinishedWithoutErrors]);

  if (csvHasErrors) {
    return <ImportErrorPage onGoBack={onGoBack} />;
  }

  return (
    <>
      <ProgressBar percentage={percentage === 0 ? 1 : percentage} />
      {rows.length > 0 && (
        <TableComponent id="validations-table" columns={columns} rows={rows} />
      )}
      {processFinishedWithErrors && (
        <FooterSticky
          canContinue={canContinue}
          handleContinue={handleContinue}
          onGoBack={onGoBack}
        />
      )}
      {showModal && (
        <ConfirmValidationModal
          onClose={() => setShowModal(false)}
          showModal={showModal}
          onConfirm={onUploadFile}
        />
      )}
    </>
  );
}
export { StudentsValidation };
