import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { EmptyFamilyPayments } from '@innovamat/glimmer-assets';
import { Typography } from '@innovamat/glimmer-components';

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const StyledSubtitle = styled(Typography.Body1)`
  max-width: 28.75rem;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const StyledImage = styled(EmptyFamilyPayments)`
  margin-top: 3.5rem;
`;

const FamilyPaymentEmptyState = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledImage />
      <TextContainer>
        <Typography.H4>{t('familyPayments.emptyState.title')}</Typography.H4>
        <StyledSubtitle>
          {t('familyPayments.emptyState.subtitle')}
        </StyledSubtitle>
      </TextContainer>
    </Container>
  );
};

export { FamilyPaymentEmptyState };
