import { SelectCourse } from '@innovamat/glow-api-client';
import styled from '@emotion/styled';
import { stages } from '@innovamat/radiance-utils';
import {
  AvatarCard,
  CardSelect,
  Grounder,
  Icon,
  Skeleton,
  Typography,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../contents';
import { useUser } from '../../../user-management';

type SelectCoursePageProps = {
  title: string;
  description: string;
  courses: SelectCourse[];
  onSelectCourse: (course: SelectCourse) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const CoursesContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 32px;
    flex-direction: column;
  }
`;

const StageTitle = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

function StagesSkeleton(): JSX.Element {
  const coursesPerStage = [3, 6, 4];

  return (
    <>
      {coursesPerStage.map((stage, stageIndex) => (
        <StageContainer key={stageIndex}>
          <StageTitle>
            <Skeleton height={'24'} width={'100'} />
          </StageTitle>
          {Array.from({ length: coursesPerStage[stageIndex] }).map(
            (_, skeletonIndex) => (
              <Skeleton key={skeletonIndex} height="64" width="100%" />
            )
          )}
        </StageContainer>
      ))}
    </>
  );
}

export function SelectCoursePage({
  title,
  description,
  courses,
  onSelectCourse,
}: SelectCoursePageProps): JSX.Element {
  const { t } = useTranslation();
  const { user } = useUser();

  const availableStages = Array.from(
    new Set(courses.map((course) => stages.getStageFromOrder(course?.order!)))
  );

  if (!user) return <></>;

  return (
    <Container>
      <PageTitle title={title} description={description} />
      <CoursesContainer>
        {courses.length === 0 ? (
          <StagesSkeleton />
        ) : (
          availableStages.map((stage) => (
            <StageContainer key={stage}>
              <StageTitle>
                {t(`common.stage-${stage?.toLocaleLowerCase()}`)}
              </StageTitle>
              {courses
                .filter(
                  (course) => stages.getStageFromOrder(course?.order!) === stage
                )
                .map((course) => (
                  <CardSelect onClick={() => onSelectCourse(course)}>
                    <AvatarCard key={course?.id}>
                      <AvatarCard.AvatarStage
                        courseName={course.name || ''}
                        courseOrder={course.order || 0}
                      />

                      <AvatarCard.Text
                        rightElement={
                          course?.available ? undefined : (
                            <Icon icon="LockedIcon" size="S" />
                          )
                        }
                      >
                        {course?.name}
                      </AvatarCard.Text>
                    </AvatarCard>
                  </CardSelect>
                ))}
            </StageContainer>
          ))
        )}
      </CoursesContainer>
      <Grounder />
    </Container>
  );
}
