import { useState } from 'react';
import { useParams } from 'react-router-domv6';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { useUser } from '../../../user-management';

import type { Teacher, Organization } from '@innovamat/glow-api-client';
import {
  useOrganizationQuery,
  useTeachersQuery,
  useClassroomQuery,
} from '@innovamat/glow-api-client';

import {
  AnnouncementPanel,
  Button,
  HeadingSection,
  Typography,
} from '@innovamat/glimmer-components';

import { ClassroomTeachersTable } from './components/teachers-table';
import { AssignTeachersToClassroomModal } from './components/assign-teachers-to-classroom-modal';
import { TeachersTableSkeleton } from './components/teachers-table/teachers-table.skeleton';
import { useCurrentNavigationValues } from '../../../contents';
import { APP_PATHS, useNavigation } from '../../../navigation';
import styled from '@emotion/styled';

const UpdatePeriodAnnouncementPanel = styled(AnnouncementPanel)`
  margin-top: 16px;
`;

const ClassroomTeachers = (): JSX.Element => {
  const { classroomId } = useParams<{ classroomId: string }>();
  const [
    isAddTeacherToClassroomModalOpen,
    setIsAddTeacherToClassroomModalOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { currentClassroom } = useCurrentNavigationValues();
  const { navigateInSameNavType } = useNavigation();

  const organizationId = user?.organizationId ?? '';

  const { data: organizationQuery } = useOrganizationQuery(
    {
      id: organizationId,
    },
    { enabled: !!organizationId }
  );

  const isPlatformEnabled =
    organizationQuery?.organization?.platformEnabled ?? false;

  const { data } = useClassroomQuery({
    id: classroomId ?? '',
    orgId: organizationQuery?.organization?.id ?? '',
  });

  const { data: teachersQuery, isLoading: isLoadingTeachers } =
    useTeachersQuery({
      classroomId: classroomId ?? '',
      organizationId,
      isPlatformEnabled,
    });

  const classroomName = data?.classroom?.name ?? '';

  const onSuccessAddTeachers = (): void => {
    const queryKey = useTeachersQuery.getKey({
      classroomId: classroomId ?? '',
      organizationId,
      isPlatformEnabled,
    });

    queryClient.invalidateQueries({ queryKey });
  };

  const showUpdateAnnouncement =
    !currentClassroom?.checked ||
    (currentClassroom.provider && !currentClassroom.providerChecked);

  return (
    <>
      <HeadingSection
        title={t('reports.admin.title.teachers')}
        actions={
          <Button
            onClick={() => setIsAddTeacherToClassroomModalOpen(true)}
            rightIcon="AddUpIcon"
            variant="secondary"
          >
            {t('teachers.buttons.addTeachers', 'Añadir profesores')}
          </Button>
        }
      />
      {showUpdateAnnouncement && (
        <UpdatePeriodAnnouncementPanel
          text={
            <Trans
              i18nKey="classroom.updatePeriod.announcement"
              components={{
                a: (
                  <Typography.Link2
                    onClick={() =>
                      navigateInSameNavType(APP_PATHS.CLASSROOM_STUDENTS_URL)
                    }
                  />
                ),
              }}
            />
          }
          type="imported"
          canClose={false}
        />
      )}
      {isLoadingTeachers || !classroomId ? (
        <TeachersTableSkeleton />
      ) : (
        <ClassroomTeachersTable
          classroomId={classroomId!}
          classroomName={classroomName}
          isPlatformEnabled={isPlatformEnabled}
          onAddTeachers={() => setIsAddTeacherToClassroomModalOpen(true)}
          organizationId={organizationId}
          teachers={(teachersQuery?.teachers as Teacher[]) ?? []}
        />
      )}

      {isAddTeacherToClassroomModalOpen && (
        <AssignTeachersToClassroomModal
          classroomId={classroomId!}
          classroomName={classroomName}
          inviterId={user?.id ?? ''}
          onClose={() => setIsAddTeacherToClassroomModalOpen(false)}
          onSuccess={onSuccessAddTeachers}
          organization={organizationQuery?.organization as Organization}
        />
      )}
    </>
  );
};

export { ClassroomTeachers };
