import { Grid, Grounder } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';

import { MyClasses, MyClassesSkeleton } from '../../../components/my-classes';
import { Shortcuts, ShortcutsSkeleton } from '../../../../contents';

import { useUser } from '../../../../user-management';
import { useEffect, useState } from 'react';

const Container = styled(Grid.Container)`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export function LandingPageTeacher(): JSX.Element {
  const { user } = useUser();

  const [showShortcuts, setShowShortcuts] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowShortcuts(true);
    }, 300);
  }, []);

  return (
    <Container>
      <Grid.Row justifyContent="center">
        <Grid.Col md={10} lg={8}>
          {user ? <MyClasses /> : <MyClassesSkeleton />}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row justifyContent="center">
        <Grid.Col md={10} lg={8}>
          {user && showShortcuts ? (
            <Shortcuts
              organizationId={user.organizationId}
              roles={user.roles}
            />
          ) : (
            <ShortcutsSkeleton />
          )}
        </Grid.Col>
      </Grid.Row>
      <Grounder />
    </Container>
  );
}
