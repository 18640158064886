import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';

export const ResourceSlider = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MockImage = styled.div`
  border-radius: 8px;
  object-fit: cover;
  width: 100%;

  img {
    border-radius: 8px;
  }
`;

export const Resource = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const ResourceText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Description = styled(Typography.Body3)`
  display: none;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
`;

export const MockSlider = styled.div`
  display: flex;
  gap: 16px;
`;

export const SkeletonContainer = styled.div`
  width: 100%;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-right: 8px;
  }
`;
