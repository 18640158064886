import { ManipulativeZone } from '@innovamat/resource-viewer';
import {
  useUser,
  useRegionParameters,
  useAppletInfo,
  useEnvVariables,
  authAxiosInstance,
} from '@innovamat/ga-features';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`;

export function ManipulativeEnvironment(): JSX.Element {
  const { regionParameters } = useRegionParameters();
  const { user } = useUser();
  const { APOLLO_SERVER } = useEnvVariables();

  const appletInfo = useAppletInfo();

  if (!regionParameters?.currency) return <></>;

  return (
    <Wrapper>
      <ManipulativeZone
        apolloServer={APOLLO_SERVER}
        axiosInstance={authAxiosInstance}
        language={user?.locale || ''}
        setEventData={() => {}}
        appletInfo={appletInfo}
        school={user?.organizationId || ''}
        uid={user?.id || ''}
      />
    </Wrapper>
  );
}
