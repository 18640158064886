import styled from '@emotion/styled';
import { Container } from '@innovamat/emotion-grid';
import { Button, Grid, Tooltip } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 20;
  left: 0;

  padding: 16px 0;
  border-top: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};

  background: ${({ theme }) => theme.tokens.color.global.white.value};
`;
const FooterContent = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
`;

type Props = {
  onGoBack: () => void;
  handleContinue: () => void;
  canContinue?: boolean;
};

function FooterSticky({
  onGoBack,
  handleContinue,
  canContinue,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Footer>
      <Container>
        <Grid.Row justifyContent="center">
          <Grid.Col xs={8}>
            <FooterContent>
              <Button variant="tertiary" onClick={onGoBack}>
                {t('common.cancel')}
              </Button>
              <Tooltip
                content={
                  canContinue
                    ? undefined
                    : t('students.import.disabled-tooltip')
                }
                popperOptions={{ strategy: 'fixed' }}
              >
                <Button disabled={!canContinue} onClick={handleContinue}>
                  {t('common.continue')}
                </Button>
              </Tooltip>
            </FooterContent>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </Footer>
  );
}

export { FooterSticky };
