import { useTranslation } from 'react-i18next';
import type { Teacher } from '@innovamat/glow-api-client';
import { Chip, Typography } from '@innovamat/glimmer-components';
import { DateTime } from 'luxon';

import { dates } from '@innovamat/radiance-utils';
import styled from '@emotion/styled';

const Date = styled(Typography.Caption)`
  color: ${(props) =>
    props.theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const formatDate = (date: string): string => {
  if (!date) return '';

  return DateTime?.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toFormat(
    dates.getDateFormat()
  );
};

const hasDateExpired = (dateString: string): boolean => {
  const inputDate = DateTime.fromSQL(dateString, { zone: 'utc' });

  const now = DateTime.utc();

  return now > inputDate;
};

const hasMoreThanAMonthPassed = (dateString: string): boolean => {
  const inputDate = DateTime.fromSQL(dateString, { zone: 'utc' });

  const datePlusOneMonth = inputDate.plus({ months: 1 });

  const now = DateTime.utc();

  return now > datePlusOneMonth;
};

const StatusCell = ({ teacher }: { teacher: Teacher }): JSX.Element => {
  const { t } = useTranslation();

  const isNonExistingTeacherInOrganization =
    teacher?.isSchoolInvitation || teacher?.isInvitation;

  const getTextNonExistingTeacher = (): string => {
    if (teacher?.isSchoolInvitation) {
      return t('generic.status.invitationSent', 'Invitacion enviada');
    }

    const isExpiredInvitation = hasDateExpired(teacher.expiresAt!);

    if (isExpiredInvitation) {
      return t('generic.status.invitationExpired', 'Invitacion expirada');
    }

    return `${t('generic.status.expiresAt', 'Expira')}: ${formatDate(
      teacher?.expiresAt ?? ''
    )}`;
  };

  const getTextExistingTeacher = (): string => {
    if (!teacher?.lastLogin) {
      return t('dates.never', 'Nunca');
    }

    const hasMoreThanAMonth = hasMoreThanAMonthPassed(teacher.lastLogin);

    if (hasMoreThanAMonth) {
      return t('dates.moreThanMonth', 'Más de un mes');
    }

    return formatDate(teacher?.lastLogin ?? '');
  };

  return (
    <Date>
      {isNonExistingTeacherInOrganization ? (
        <StatusContainer>
          <Chip text={t('generic.status.pending', 'Pendiente')} />
          <span>{getTextNonExistingTeacher()}</span>
        </StatusContainer>
      ) : (
        <StatusContainer>
          <Chip
            text={t('generic.status.activeAccess', 'Acceso activo')}
            type="success"
          />
          <span>
            {t('dates.lastActivity', 'Ultima actividad')}:{' '}
            {getTextExistingTeacher()}
          </span>
        </StatusContainer>
      )}
    </Date>
  );
};

export { StatusCell };
