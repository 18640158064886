import { Input } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../../hooks/use-organization';
import styled from '@emotion/styled';

type Props = {
  search: string;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const StyledInput = styled(Input)`
  width: 308px;
`;

function StudentSearchInput({ search, handleSearch }: Props): JSX.Element {
  const { t } = useTranslation();
  const { isOrgAnonymized } = useOrganization();
  return (
    <StyledInput
      leftIcon="SearchIcon"
      placeholder={
        isOrgAnonymized
          ? t('students.search.alias.placeholder')
          : t('students.search.placeholder')
      }
      onChange={handleSearch}
      value={search}
    />
  );
}

export { StudentSearchInput };
