import styled from '@emotion/styled';
import { Typography } from '../Typography';
import { useGlimmerTheme } from '../../theme';
import { MenuItem } from '../menu-item';
import { ProfileButton } from './profile-button';
import React from 'react';

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-subtle'].value};
  margin: 16px 0;
`;

const DropdownContent = styled.div`
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  padding: 16px;
  width: 312px;
`;

export function ProfileButtonContent({
  name,
  email,
  items,
}: ProfileButton): JSX.Element {
  const theme = useGlimmerTheme();

  return (
    <DropdownContent>
      <ProfileInfo>
        <Typography.Subtitle1>{name}</Typography.Subtitle1>
        <Typography.Body3
          color={theme.tokens.color.alias.cm.text['text-subtle'].value}
        >
          {email}
        </Typography.Body3>
      </ProfileInfo>

      <Divider />

      {items.map((item, index) => !item.hidden && (
        <React.Fragment key={index}>
          <MenuItem
            dataTestId={item.id}
            onClick={item.onClick}
            size="L"
            text={item.text}
            icon={item.icon}
            rightIcon={item.rightIcon}
            iconSize="M"
            fill
            state={item.state}
          />
          {item.hasDivider && <Divider />}
        </React.Fragment>
      ))}
    </DropdownContent>
  );
}
