import styled from '@emotion/styled';

export const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: $dark02;
  margin-top: -20px;
  line-height: 24px;
`;

export const InputEmailContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  height: 40px;

  > div:first-child {
    flex: 1;
  }
`;

export const UnlinkIcon = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .showMore {
    width: 16px !important;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EmailsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
