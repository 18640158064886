import { useLayoutEffect, useState } from 'react';

import { Link, useNavigate, useParams, useLocation } from 'react-router-domv6';
import { Trans, useTranslation } from 'react-i18next';

import { APP_PATHS, useUser } from '@innovamat/ga-features';

import styled from '@emotion/styled';

import {
  AnnouncementPanel,
  Button,
  Cart,
  InnerHtml,
  Modal,
  TitleHeader,
} from '@innovamat/glimmer-components';

import { locales } from '@innovamat/localization';

import { useCartExitPrompt } from '../../hooks/use-cart-exit-prompt';
import { useParseMaterials } from './hooks/use-parse-materials';
import { useTermsModalVisibility } from './hooks/use-terms-modal-visibility';

import { useCurrentMaterialsContext } from '../../providers/use-current-materials-provider';

import StagedMaterialsList from './components/staged-materials-list';

import {
  useCheckHasOngoingOrdersQuery,
  useCourses,
  useGetCurrentMaterialsQuery,
} from '@innovamat/glow-api-client';

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledAtag = styled.a`
  text-decoration: underline;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  margin-top: 1.5rem;
  width: 330px;

  & > .cartContainer {
    display: flex;
    justify-content: right;
    position: sticky;
    top: 87px;
  }
`;

const LeftContainer = styled.div`
  margin-right: 60px;
  width: calc(100% - 390px);
`;

function CurrentMaterials(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { organizationId } = useParams();

  const BASE_URL = pathname ? pathname.split('/').slice(0, -1).join('/') : '';

  const { user } = useUser();

  const SEMANTIC_MODAL_TYPE = {
    ERROR: { type: 'error', text: t('common.modal.error') },
    SUCCESS: { type: 'success', text: t('common.modal.success') },
    WARNING: { type: 'warning', text: t('common.modal.important') },
  } as const;

  const {
    cart,
    isEditMode,
    stagedMaterials,
    storedOrganizationId,
    clearCart,
    init,
    orderMaterial,
    returnMaterial,
    setIsEditMode,
  } = useCurrentMaterialsContext();

  const { courses, isLoading: isLoadingCourses } = useCourses({
    organizationId: organizationId!,
    regionCode: user ? user.region : '',
  });

  const {
    data: materialsData,
    error,
    isLoading: isLoadingMaterials,
  } = useGetCurrentMaterialsQuery({
    organizationId: user?.organizationId! || '',
    academicYearId: user?.organization?.academicYearId! || '',
  });

  const isSameOrganization = storedOrganizationId === organizationId;

  const { data: ongoingOrders } = useCheckHasOngoingOrdersQuery({
    organizationId: user?.organization?.id ?? '',
  });

  const { shownMaxReturnableItemsDialog, setTermsDialogToViewed } =
    useTermsModalVisibility();

  useCartExitPrompt(cart.length === 0);

  useParseMaterials(
    materialsData,
    courses,
    init,
    !!cart.length,
    isSameOrganization,
    organizationId as string
  );

  const [isOpenedModalModifyOrder, setIsOpenedModalModifyOrder] =
    useState(false);
  const [isReturnableItemsModalOpen, setIsReturnableItemsModalOpen] =
    useState(false);

  const handleAcceptCart = (): void => {
    setIsOpenedModalModifyOrder(true);
  };

  const handleCancelCart = (): void => {
    setIsEditMode(false);
    clearCart();
  };

  const handleCancelModal = (): void => {
    setIsOpenedModalModifyOrder(false);
  };

  const handleOrderMaterial = (stageId: string, materialId: string): void => {
    setIsReturnableItemsModalOpen(false);
    orderMaterial(stageId, materialId);
  };

  const handleCloseReturnableItemsModal = (): void => {
    setTermsDialogToViewed();
    setIsReturnableItemsModalOpen(false);
  };

  const handleConfirmModal = (): void => {
    setIsOpenedModalModifyOrder(false);
    navigate(`${BASE_URL}${APP_PATHS.MATERIALS_CHECKOUT_URL}`);
  };

  const handleClickModify = (): void => {
    setIsEditMode(true);
  };

  const renderNotification = (message: string): JSX.Element => {
    if (ongoingOrders?.checkHasOngoingOrders.hasOngoingOrders) {
      return (
        <AnnouncementPanel
          canClose={false}
          style={{ marginTop: '1.5rem' }}
          type="warning"
          text={
            <p>
              {message}{' '}
              <Link to={`${BASE_URL}${APP_PATHS.HISTORY_URL}`}>
                {t('currentMaterials.activeOrdersReturnsNotification.link')}
              </Link>
            </p>
          }
        />
      );
    }
    return <></>;
  };

  const handleReturnMaterial = (
    stageId: string,
    materialId: string,
    isMaxReturnableItemsReached: boolean
  ): void => {
    setIsReturnableItemsModalOpen(isMaxReturnableItemsReached);

    returnMaterial(stageId, materialId);
  };

  const renderLeftContainer = (): JSX.Element => {
    if (
      !isLoadingMaterials &&
      !isLoadingCourses &&
      !materialsData?.getCurrentMaterials?.length &&
      stagedMaterials.length === 0
    ) {
      return (
        <>
          {renderNotification(
            t(
              'currentMaterials.activeOrdersReturnsNotification.messageNoMaterials'
            )
          )}
          {!ongoingOrders?.checkHasOngoingOrders.hasOngoingOrders && (
            <div style={{ marginTop: '1.5rem' }}>
              <p>{t('currentMaterials.noMaterialsMessage')}</p>
            </div>
          )}
        </>
      );
    }

    return (
      <>
        {!isLoadingMaterials &&
          !isLoadingCourses &&
          renderNotification(
            t('currentMaterials.activeOrdersReturnsNotification.message')
          )}
        <StagedMaterialsList
          hasErrors={!!error}
          isEditMode={isEditMode}
          isLoading={isLoadingMaterials || isLoadingCourses}
          onOrderMaterial={handleOrderMaterial}
          onReturnMaterial={handleReturnMaterial}
          stagedMaterials={stagedMaterials}
        />
      </>
    );
  };

  useLayoutEffect(() => {
    if (!isSameOrganization) {
      setIsEditMode(false);
    }
  }, []);

  return (
    <>
      <TitleHeader title={t('currentMaterials.title')} />
      <Container>
        <LeftContainer>{renderLeftContainer()}</LeftContainer>

        <RightContainer>
          <div className="cartContainer">
            {!isEditMode ? (
              <div>
                <Button onClick={handleClickModify} variant="secondary">
                  {t('common.modify')}
                </Button>
              </div>
            ) : (
              <Cart
                cancelButtonLabel={t('common.cancel')}
                confirmButtonLabel={t('common.accept')}
                materials={cart}
                onAccept={handleAcceptCart}
                onCancel={handleCancelCart}
                noModificationsMessage={t(
                  'currentMaterials.cart.noModificationsMessage'
                )}
                ordersLabel={t('common.orders')}
                returnsLabel={t('common.returns')}
                title={t('common.summary')}
              />
            )}
          </div>
        </RightContainer>
      </Container>
      {isOpenedModalModifyOrder && (
        <Modal
          semantic={SEMANTIC_MODAL_TYPE.WARNING}
          buttons={[
            {
              children: t('currentMaterials.modalModifyOrder.confirmButton'),
              onClick: handleConfirmModal,
              variant: 'accent',
            },
            {
              children: t('common.cancel'),
              onClick: handleCancelModal,
              variant: 'secondary',
            },
          ]}
          closeButton="inner"
          isOpen={isOpenedModalModifyOrder}
          modalWidth="40rem"
          onClose={handleCancelModal}
          title={t('currentMaterials.modalModifyOrder.title')}
        >
          <InnerHtml text={t('currentMaterials.modalModifyOrder.message')} />
        </Modal>
      )}

      {shownMaxReturnableItemsDialog && (
        <Modal
          semantic={SEMANTIC_MODAL_TYPE.WARNING}
          modalWidth="40rem"
          onClose={handleCloseReturnableItemsModal}
          isOpen={isReturnableItemsModalOpen}
          closeButton="inner"
          buttons={[
            {
              children: t('common.close'),
              onClick: handleCloseReturnableItemsModal,
              variant: 'accent',
            },
          ]}
          title={t('currentMaterials.returnableItemsModal.title')}
        >
          <Trans
            i18nKey="currentMaterials.returnableItemsModal.message"
            components={{
              historyLink: (
                <StyledLink
                  onClick={handleCloseReturnableItemsModal}
                  to="/school/ordersReturnsHistory"
                />
              ),
              returnPolicyLink: (
                <StyledAtag
                  href={`https://innovamat.zendesk.com/hc/${locales.getZendesk(
                    user?.locale || 'es' //Todo check if this is correct
                  )}/articles/18202437853329-Pol%C3%ADtica-de-devoluciones-de-Innovamat-Education`}
                  target="blank"
                />
              ),
            }}
          />
        </Modal>
      )}
    </>
  );
}

export { CurrentMaterials };
