import styled from '@emotion/styled';
import { Button, Typography, theme } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type Props = {
  isWarningList: boolean;
  isCurrentWeek: boolean;
  onPreviousWeek: () => void;
  showPreviousWeekButton: boolean;
};

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 58px;
`;

const ButtonContainer = styled(Button)`
  margin-top: 24px;
`;

function MessageReports({
  isWarningList,
  isCurrentWeek,
  onPreviousWeek,
  showPreviousWeekButton,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const messageReports = isCurrentWeek
    ? t('reports.message.thisWeek.classDontDoDPYet')
    : t('reports.message.duringThisWeek.classDidntDoDP');
  const title = isWarningList ? t('reports.warningList.title') : messageReports;
  const description = isWarningList
    ? t('reports.warningList.description')
    : t('reports.digitalPractice.weeklyReminder');

  const warningColor = theme.tokens.color.specific.reports.semantic.low.value;
  const textSecondaryColor =
    theme.tokens.color.alias.cm.text['text-subtle'].value;

  return (
    <MessageContainer>
      <Typography.H3 color={isWarningList ? warningColor : ''}>
        {title}
      </Typography.H3>
      <Typography.Body1
        color={isWarningList ? warningColor : textSecondaryColor}
      >
        {description}
      </Typography.Body1>
      {showPreviousWeekButton && (
        <ButtonContainer onClick={onPreviousWeek}>
          {t('reports.weekly.button.previousWeek')}
        </ButtonContainer>
      )}
    </MessageContainer>
  );
}

export default MessageReports;
