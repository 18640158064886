import styled from '@emotion/styled';
import { SimpleBlock } from '../SimpleBlock';
import { StandardTags } from '../StandardTags';
import { ContentBlockTags } from '../ContentBlockTags';
import { DimensionTags } from '../DimensionTags';
import { Materials } from '../Materials';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import { AssessableSummaryPrintable } from '../assessables/assessables-summary';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

export function SummarySectionPrintable() {
  const {
    t,
    smartGuide: { summary },
  } = useSmartGuides();

  return (
    <Section>
      {summary.inThisTaller.text && (
        <SimpleBlock title={t(summary.inThisTaller.titleKey)}>
          <PreprInnerHtml text={summary.inThisTaller.text} />
        </SimpleBlock>
      )}
      {summary.withIntentionOf.text && (
        <SimpleBlock title={t(summary.withIntentionOf.titleKey)}>
          <PreprInnerHtml text={summary.withIntentionOf.text} />
        </SimpleBlock>
      )}
      {summary.vocabularyKey.text && (
        <SimpleBlock title={t(summary.vocabularyKey.titleKey)}>
          <PreprInnerHtml text={summary.vocabularyKey.text} />
        </SimpleBlock>
      )}
      {summary.assessables.length > 0 && <AssessableSummaryPrintable />}
      <ContentBlockTags
        tags={summary.contentBlockTags}
        hasAssessables={summary.assessables.length > 0}
      />
      <DimensionTags tags={summary.dimensionTags} />
      {/* {summary.standardTags && <StandardTags tags={summary.standardTags} />} */}
      <Materials {...summary.materials} />
    </Section>
  );
}
