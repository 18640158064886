import styled from '@emotion/styled';
import {
  ButtonProps,
  Modal,
  RadioButton,
  TableTag,
  Typography,
  getTypography,
  snack,
} from '@innovamat/glimmer-components';
import { useEffect, useState } from 'react';
import { ClassroomSchedule } from '../classroom-schedule';
import {
  SCHEDULE_DEFAULT_VALUES,
  useClassroomScheduleValues,
} from '../../hooks/use-classroom-schedule-values';
import {
  ClassroomBody,
  useClassroomQuery,
  useOrganizationQuery,
} from '@innovamat/glow-api-client';
import {
  DIGITAL_PRACTICE_ACCESS,
  DigitalPracticeAccess,
  usePutClassrooom,
} from '../../hooks/use-put-classroom';
import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';

type HomeAccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isDisclaimer?: boolean;
  classroomId: string;
  organizationId: string;
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RadioButtonDescription = styled.ul`
  ${({ theme }) => getTypography(theme, 'Body 2')};
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  margin-left: 40px;

  li {
    padding-left: 4px;
  }

  li::marker {
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
    font-size: 12px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function HomeAccessModal({
  isOpen,
  onClose,
  isDisclaimer,
  classroomId,
  organizationId,
}: HomeAccessModalProps): JSX.Element {
  const schedule = useClassroomScheduleValues();
  const queryClient = useQueryClient();
  const [isInternalDisclaimer, setIsInternalDisclaimer] =
    useState(isDisclaimer);
  const [invalidatingCache, setInvalidatingCache] = useState(false);
  const { t } = useTranslation();

  const handleOnSuccess = async () => {
    setInvalidatingCache(true);
    await queryClient.invalidateQueries({
      queryKey: [
        'Students',
        {
          classroomId,
          organizationId,
        },
      ],
    });
    await queryClient.invalidateQueries({
      queryKey: [
        'Classroom',
        {
          id: classroomId,
          orgId: organizationId,
        },
      ],
    });
    snack.success(t('common.toast.success'));
    setInvalidatingCache(false);
    onClose();
  };

  const handleOnError = () => {
    snack.error(t('common.toast.error'));
    onClose();
  };

  const handleOnSend = () => {
    if (!classroom) return;
    const body: ClassroomBody = {
      id: classroom.id,
      icon: classroom.icon,
      organizationId,
      level: classroom.level,
      name: classroom.name,
      courseId: classroom.courseId,
      avatar: classroom.avatar,
      scheduleEnd: schedule.values.scheduleEnd,
      scheduleStart: schedule.values.scheduleStart,
    };
    handlePutClassroom(body, schedule.values.timeZone!, homeAccessMode);
  };

  const { handlePutClassroom, isPending } = usePutClassrooom({
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });

  const { data: organizationQuery } = useOrganizationQuery({
    id: organizationId,
  });

  const { data: classroomQuery } = useClassroomQuery({
    id: classroomId,
    orgId: organizationId,
  });
  const classroom = classroomQuery?.classroom;
  const [homeAccessMode, setHomeAccessMode] = useState<DigitalPracticeAccess>(
    (classroom?.digitalPracticeAccess as DigitalPracticeAccess) ||
      DIGITAL_PRACTICE_ACCESS.NO_ACCESS
  );

  useEffect(() => {
    const timezone = organizationQuery?.organization?.timezone || '';
    schedule.handleInitValues({
      scheduleStart:
        classroom?.scheduleStart || SCHEDULE_DEFAULT_VALUES.scheduleStart,
      scheduleEnd:
        classroom?.scheduleEnd || SCHEDULE_DEFAULT_VALUES.scheduleEnd,
      timeZone: timezone,
    });
    setHomeAccessMode(
      (classroom?.digitalPracticeAccess as DigitalPracticeAccess) ||
        DIGITAL_PRACTICE_ACCESS.NO_ACCESS
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classroom, organizationQuery]);

  useEffect(() => {
    setIsInternalDisclaimer(isDisclaimer);
  }, [isOpen, isDisclaimer]);

  const getButtons = (): ButtonProps[] => {
    if (isInternalDisclaimer) {
      return [
        {
          children: 'Configurar',
          variant: 'accent',
          onClick: () => {
            setIsInternalDisclaimer(false);
          },
        },
        {
          children: 'Cancelar',
          variant: 'tertiary',
          onClick: onClose,
        },
      ];
    }

    return [
      {
        children: 'Guardar cambios',
        variant: 'accent',
        loading: isPending || invalidatingCache,
        onClick: handleOnSend,
      },
      {
        children: 'Cancelar',
        variant: 'tertiary',
        disabled: isPending || invalidatingCache,
        onClick: onClose,
      },
    ];
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('students.home-access-modal.title')}
      onClose={onClose}
      buttons={getButtons()}
      modalWidth={692}
    >
      <>
        {isInternalDisclaimer ? (
          t('students.home-access-modal.disclaimer')
        ) : (
          <ContentContainer>
            <ContentBlock>
              <ClassroomSchedule {...schedule} />
            </ContentBlock>
            <ContentBlock>
              <Typography.Subtitle2>
                {t('students.home-access-modal.digital-practice-mode.subtitle')}
              </Typography.Subtitle2>
              <FlexContainer>
                <RadioButton
                  groupBy="access-0"
                  label={t('students.home-access-modal.no-access')}
                  value={DIGITAL_PRACTICE_ACCESS.NO_ACCESS}
                  checked={homeAccessMode === DIGITAL_PRACTICE_ACCESS.NO_ACCESS}
                  onClick={() => {
                    setHomeAccessMode(DIGITAL_PRACTICE_ACCESS.NO_ACCESS);
                  }}
                />
                <TableTag type="accent" text="Recomendada" />
              </FlexContainer>
              <RadioButtonDescription>
                <Trans
                  i18nKey="students.home-access-modal.no-access.description"
                  components={{
                    li: <li />,
                  }}
                />
              </RadioButtonDescription>
              <RadioButton
                groupBy="access-1"
                label={t('students.home-access-modal.home-training')}
                value={DIGITAL_PRACTICE_ACCESS.HOME_TRAINING}
                checked={
                  homeAccessMode === DIGITAL_PRACTICE_ACCESS.HOME_TRAINING
                }
                onClick={() => {
                  setHomeAccessMode(DIGITAL_PRACTICE_ACCESS.HOME_TRAINING);
                }}
              />

              <RadioButtonDescription>
                <Trans
                  i18nKey="students.home-access-modal.home-training.description"
                  components={{
                    li: <li />,
                  }}
                />
              </RadioButtonDescription>
              <RadioButton
                groupBy="access-2"
                label={t('students.home-access-modal.home-access')}
                value={DIGITAL_PRACTICE_ACCESS.HOME_ACCESS}
                checked={homeAccessMode === DIGITAL_PRACTICE_ACCESS.HOME_ACCESS}
                onClick={() => {
                  setHomeAccessMode(DIGITAL_PRACTICE_ACCESS.HOME_ACCESS);
                }}
              />

              <RadioButtonDescription>
                <Trans
                  i18nKey="students.home-access-modal.home-access.description"
                  components={{
                    li: <li />,
                  }}
                />
              </RadioButtonDescription>
            </ContentBlock>
          </ContentContainer>
        )}
      </>
    </Modal>
  );
}
