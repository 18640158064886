import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  InnerHtml,
  SegmentedButtons,
  Select,
  State,
  Tooltip,
  Typography,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { DownloadReportType } from '../../hooks/use-download-reports';
import { PracticePeriodOptions } from '../../hooks/use-practice-period';
import useSelectWeek from '../../hooks/use-select-week';
import { IconType } from '@innovamat/glimmer-icons';
import { ReportDate } from './weekly-results';

export type ReportsOptions = {
  value: string;
  label: string;
  date_from?: string | undefined;
  date_to?: string | undefined;
  title?: string | null | undefined;
};

type Props = {
  reportDate: ReportDate | null;
  handleSelectReportDate: (reportDate: ReportDate) => void;
  expandedColumn: boolean;
  loadingTable: boolean;
  reportsOptions: ReportsOptions[];
  tableHasData: boolean;
  headerRef: RefObject<HTMLDivElement>;
  handleOpenSummaryDrawer: () => void;
  setCurrentReport: (report: string) => void;
  handleDownload: (type: DownloadReportType) => void;
  googleDriveLoading: boolean;
  isDisabledSummaryButton: boolean;
  practicePeriodOptions: PracticePeriodOptions;
};

function WeeklyResultsHeader({
  reportDate,
  handleSelectReportDate,
  expandedColumn,
  loadingTable,
  reportsOptions,
  tableHasData,
  headerRef,
  handleOpenSummaryDrawer,
  setCurrentReport,
  handleDownload,
  googleDriveLoading,
  isDisabledSummaryButton,
  practicePeriodOptions,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { onChangeReportDate, options } = useSelectWeek({
    reportsOptions,
    setCurrentReport,
    handleSelectReportDate,
  });

  const downloadReportsOptions = [
    {
      children: <InnerHtml text={t('reports.button.downloadReportExcel')} />,
      onSelectItem: () => handleDownload('excel'),
      icon: 'FileXlsIcon' as IconType,
      state: 'active' as State,
    },
    {
      children: <InnerHtml text={t('reports.button.downloadReportPdf')} />,
      onSelectItem: () => handleDownload('pdf'),
      icon: 'FilePdfIcon' as IconType,
      state: 'active' as State,
    },
    {
      children: t('reports.button.downloadReportDrive'),
      onSelectItem: () => handleDownload('drive'),
      icon: 'GoogleDriveIcon' as IconType,
      state: googleDriveLoading ? 'disabled' : ('active' as State),
    },
  ];

  const openDownloadDropdownOnHover = !loadingTable && tableHasData;

  return (
    <>
      <ReportsHeader id="reports-header" ref={headerRef}>
        <ReportsActions expandedColumn={expandedColumn}>
          <LeftSideContainer>
            <SelectWeekContainer>
              <SectionTitle>{t('reports.weekPicker.title')}</SectionTitle>
              <Select
                menuPortalTarget={document.body}
                options={options}
                onChange={(option) => onChangeReportDate(option!.value)}
                value={reportDate?.from}
                placeholder={t('classroom.reports.selectWeekPlaceholder')}
                dataTestId="DROPDOWNBUTTON"
              />
            </SelectWeekContainer>
            <SegmentedButtonsContainer>
              <SectionTitle>
                {t('reports.timeFilter.button.title')}
              </SectionTitle>
              <SegmentedButtons options={practicePeriodOptions} />
            </SegmentedButtonsContainer>
          </LeftSideContainer>
          <RightSideContainer>
            <Tooltip
              content={
                isDisabledSummaryButton &&
                t('reports.weeklyNotes.tooltip.currentWeek')
              }
              popperOptions={{ strategy: 'fixed' }}
            >
              <Button
                variant="secondary"
                leftIcon="FileStandardIcon"
                onClick={handleOpenSummaryDrawer}
                disabled={isDisabledSummaryButton}
                dataTestId="WERE_SUMMARYBUTTON"
              >
                {t('reports.weeklyNotes.title')}
              </Button>
            </Tooltip>
            <Dropdown openOnHover={openDownloadDropdownOnHover}>
              <Dropdown.Toggle>
                <Button
                  variant="secondary"
                  loading={false}
                  onClick={() => {}}
                  leftIcon="DownloadIcon"
                  rightIcon="ExpandMoreIcon"
                  disabled={loadingTable || !tableHasData}
                  dataTestId="WERE_DOWNLOADBUTTON"
                />
              </Dropdown.Toggle>
              <DropdownContent position={'bottomRight'}>
                {downloadReportsOptions.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    {...option}
                    dataTestId={`WERE_DOWNLOADITEM_${index + 1}`}
                  />
                ))}
              </DropdownContent>
            </Dropdown>
          </RightSideContainer>
        </ReportsActions>
      </ReportsHeader>
    </>
  );
}

export default WeeklyResultsHeader;

const ReportsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  padding-bottom: 8px;
  margin-top: 8px;
`;

export const RightSideContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const DropdownContent = styled(Dropdown.Content)`
  z-index: 20;
`;

const ReportsActions = styled.div<{ expandedColumn: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const LeftSideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const SelectWeekContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 251px;
  }
`;

const SegmentedButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled(Typography.Body2)`
  margin-bottom: 4px;
`;
