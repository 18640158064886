import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import { MenuItem, MenuSection } from '@innovamat/glimmer-components';
import { IconType } from '@innovamat/glimmer-icons';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-domv6';
import { useCurrentNavigationValues, useNavigation } from '../../hooks';
import {
  ProcessedSectionItem,
  ProcessedSitemapTree,
} from '../../hooks/process-sitemap-tree';
import { LateralMenuSkeleton } from './lateral-menu-skeleton';

type LateralMenuProps = {
  sitemapTree?: ProcessedSitemapTree;
  isMobile?: boolean;
  onNavigate?: () => void;
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

export function LateralMenu({
  sitemapTree,
  isMobile,
  onNavigate,
}: LateralMenuProps): JSX.Element {
  const { menu, submenu } = useParams();
  const { pathname } = useLocation();
  const { navigateInSameNavType } = useNavigation();
  const { getTypeOfPagePermission, currentClassroom, currentCourse } =
    useCurrentNavigationValues();
  const actualRoute = submenu ? `/${menu}/${submenu}` : `/${menu}`;
  const { setEventData } = useEventLogging();
  const [selected, setSelected] = useState(actualRoute);

  const [openSections, setOpenSections] = useState<string[]>([]);

  const handleSectionClick = (value: string): void => {
    if (openSections.includes(value)) {
      setOpenSections(openSections.filter((section) => section !== value));
    } else {
      setOpenSections([...openSections, value]);
    }
  };

  const handleNavigateTo = (
    section: ProcessedSectionItem,
    openNewTab?: boolean
  ): void => {
    onNavigate?.();

    if (!section || typeof section === 'string') return;

    const pageId = section.fullPageId || section.demoPageId;

    if (pageId) {
      setEventData('change_page', {
        page_id: pageId,
        page_name: section.value,
        page_type: getTypeOfPagePermission(section),
      });
    } else {
      setEventData('change_menu', {
        item_name: section.route,
        classroom_id: currentClassroom?.id,
        course: currentCourse?.order,
      });
    }

    if (openNewTab) {
      window.open(section.route, '_blank')?.focus();
    } else {
      setSelected(section.route as string);
      navigateInSameNavType(section.route as string);
    }
  };

  useEffect(() => {
    if (!sitemapTree) return;
    setSelected(actualRoute);

    setOpenSections(() => {
      const section = sitemapTree?.find((section) => {
        if (section && 'items' in section) {
          return section.items?.find(
            (item) => typeof item !== 'string' && item.route === actualRoute
          );
        }
        return false;
      });
      return [...openSections, section?.value!];
    });

    return () => {
      setOpenSections([]);
    };
  }, [sitemapTree, pathname, actualRoute]);

  if (sitemapTree === undefined || sitemapTree.length === 0) {
    return <LateralMenuSkeleton />;
  }

  return (
    <MenuContainer>
      {sitemapTree?.map((section) => {
        if (!section) return null;
        if (section.menuItemType === 'item') {
          return (
            <MenuItem
              key={section.id}
              text={section.value}
              subtleText={section.subtle}
              icon={section.icon as IconType}
              state={selected === section.route ? 'selected' : 'active'}
              onClick={() => handleNavigateTo(section)}
              size={isMobile ? 'XXL' : 'L'}
            />
          );
        }

        return (
          <MenuSection
            key={section.id}
            text={section.value}
            icon={section.icon as IconType}
            size={isMobile ? 'L' : 'M'}
            isOpen={openSections.includes(section.value)}
            onClick={() => handleSectionClick(section.value)}
            content={
              section.items
                ? section.items.map((item) => {
                    if (typeof item === 'string') {
                      return item;
                    }

                    return {
                      id: item.id,
                      text: item.value,
                      subtleText: item.subtle,
                      rightIcon: item.openNewTab ? 'OpenNewTabIcon' : undefined,
                      rightIconSize: 'S',
                      state: selected === item.route ? 'selected' : 'active',
                      onClick: () => handleNavigateTo(item, item.openNewTab),
                    };
                  })
                : []
            }
          />
        );
      }) || []}
    </MenuContainer>
  );
}
