import styled from '@emotion/styled';
import { useMemo } from 'react';
import { ClassroomAvatar } from '../classroom-avatar';
import { Tooltip } from '../Tooltip';
import { Button } from '../Button';

export type ClassroomAvatarGroupItem = {
  name: string;
  image: string;
};

type Props = {
  classrooms: ClassroomAvatarGroupItem[];
  isUpdatePeriod: boolean;
  onClick: () => void;
  emptyStateText: string;
  tooltipText: string;
};

const AvatarGrouWrapper = styled.div`
  display: flex;

  > div {
    margin-left: 0;

    &:not(:first-of-type) {
      margin-left: -30px;
    }
  }
`;

const CustomButton = styled(Button)`
  padding-right: 8px;
  padding-left: 8px;
`;

const GroupButton = styled(Button)`
  padding: 4px;
  max-height: none;
`;

function ClassroomAvatarGroup({
  classrooms,
  isUpdatePeriod,
  onClick,
  emptyStateText,
  tooltipText,
}: Props): JSX.Element {
  const tooltipContent = useMemo(() => {
    if (!classrooms) return '';

    if (classrooms.length >= 3) {
      return tooltipText;
    }

    const names = classrooms.map((classroom) => classroom.name).join(', ');

    return names;
  }, [classrooms, tooltipText]);

  if (!classrooms?.length)
    return (
      <CustomButton
        variant={'tertiary'}
        onClick={() => !isUpdatePeriod && onClick()}
        rightIcon="AddUpIcon"
        disabled={isUpdatePeriod}
        role="button"
      >
        {emptyStateText}
      </CustomButton>
    );

  return (
    <Tooltip
      content={tooltipContent}
      popperOptions={{ strategy: 'fixed' }}
      placement="top"
    >
      <GroupButton
        variant={'tertiary'}
        onClick={() => !isUpdatePeriod && onClick()}
        disabled={isUpdatePeriod}
        role="button"
      >
        <AvatarGrouWrapper>
          {classrooms.slice(0, 4).map((classroom) => (
            <ClassroomAvatar avatar={classroom.image} />
          ))}
        </AvatarGrouWrapper>
      </GroupButton>
    </Tooltip>
  );
}

export { ClassroomAvatarGroup };
