/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useMemo, useRef, useState } from 'react';
import { TFunction } from 'i18next';
import styled from '@emotion/styled';
import {
  Chip,
  FileNameButton,
  FloatingWidget,
} from '@innovamat/glimmer-components';

import { useMedia } from '@innovamat/hooks';

import {
  useGetCSVImports,
  statusToSemantic,
  useUser,
} from '@innovamat/ga-features';
import type { CSVImport } from '@innovamat/ga-features';
import { NavLink } from 'react-router-domv6';
import { useTranslation } from 'react-i18next';

const Details = styled.div`
  max-height: 110px;
  overflow: auto;
  padding: 8px 20px 8px 4px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledNavLink = styled(NavLink)`
  overflow: hidden;
  width: 100%;

  .filename-tooltip {
    display: block;
    overflow: hidden;
  }
`;

export function ImportsWidget() {
  const { user, isRole } = useUser();
  const { t } = useTranslation();
  const [showImports, setShowImports] = useState<boolean>(false);
  const isAdminOrOwner = isRole.Admin || isRole.Owner;
  const {
    data: imports,
    isSuccess,
    isLoading,
  } = useGetCSVImports({
    organizationId: user?.organizationId,
    enabled: isAdminOrOwner,
  });

  const importsRoute = `organization/${user?.organizationId}/imports`;
  const isHidden = useMedia(960);

  const filterByToday = (importItem: CSVImport) => {
    return new Date(importItem.createdAt).getDate() === new Date().getDate();
  };

  const todayImports = useMemo(
    () => imports?.filter(filterByToday) || [],
    [imports]
  );

  const processingImports = todayImports.filter(
    (item) => item.status === 'processing' || item.status === 'queued'
  );

  const prevImportsLength = useRef<number>(0);

  if (prevImportsLength.current === 0 && todayImports.length !== 0) {
    prevImportsLength.current = todayImports.length;
  }

  const handleShowWidget = () => {
    const newImports = prevImportsLength.current < todayImports.length;
    const noProcessingImports = processingImports.length === 0;
    const importsLength = todayImports.length;

    if (!noProcessingImports || newImports) {
      setShowImports(true);
    }

    if (importsLength === 0 || !isAdminOrOwner) {
      setShowImports(false);
    }
  };

  useEffect(() => {
    handleShowWidget();
  }, [isSuccess, imports, isAdminOrOwner]);

  const handleClose = () => {
    prevImportsLength.current = todayImports.length;
    if (processingImports.length === 0) {
      localStorage.setItem('hideImportsWidget', 'true');
    }
  };

  if (isHidden) return null;

  if (isLoading || !showImports) return null;

  return (
    <FloatingWidget
      onClose={() => handleClose()}
      title={t('imports.floatingWidget.title')}
    >
      <Details>
        {todayImports?.map(({ originalFilename, status }, index) => (
          <div key={index}>
            <StyledNavLink to={importsRoute}>
              <FileNameButton fileName={originalFilename} />
            </StyledNavLink>

            <Chip
              type={statusToSemantic(status)}
              text={t(`imports.status.${status}`)}
            />
          </div>
        ))}
      </Details>
    </FloatingWidget>
  );
}
