import { Button } from '@innovamat/glimmer-components';
import { useCheckStudentBySisIdQuery } from '@innovamat/glow-api-client';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { ERRORS, StudentValidation } from '../types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

type Props = {
  sisId: string;
  setStudents: Dispatch<SetStateAction<(StudentValidation | null)[]>>;
};

const Wrapper = styled.div`
  height: 63px;
  align-items: center;
  display: flex;
`;

function RevalidateButton({ sisId, setStudents }: Props): JSX.Element {
  const { t } = useTranslation();
  const { data, isSuccess, refetch, isLoading } = useCheckStudentBySisIdQuery(
    {
      sisId,
    },
    {
      enabled: false,
    }
  );

  const handleRevalidate = (): void => {
    refetch();
  };

  useEffect(() => {
    if (isSuccess && data) {
      setStudents((prev) => {
        const newStudents = [...prev];
        const studentIndex = prev.findIndex(
          (student) => student?.sisId === sisId
        );

        const studentData = prev[studentIndex]!;

        const errorData = data?.checkStudentBySisId?.[0];

        if (!errorData) {
          newStudents[studentIndex] = null;
          return newStudents;
        }

        if (studentIndex !== -1) {
          newStudents[studentIndex] = {
            errorType: ERRORS.EXISTS,
            rowNumber: studentData.rowNumber,
            studentName: studentData.studentName,
            sisId: studentData.sisId,
            overwrites: {
              classroom: errorData.data.classroom,
              courseOrder: errorData.data.course_order,
              firstName: errorData.data.first_name,
              lastName: errorData.data.last_name,
              sisId: errorData.data.sis_id,
            },
          };
        }

        return newStudents;
      });
    }
  }, [isSuccess, data, setStudents, sisId]);

  return (
    <Wrapper>
      <Button size="S" onClick={handleRevalidate} loading={isLoading}>
        {t('students.import.retry')}
      </Button>
    </Wrapper>
  );
}

export { RevalidateButton };
