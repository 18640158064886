import styled from '@emotion/styled';
import { EmptyClassroom } from '@innovamat/glimmer-assets';
import { Button, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const Description = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type EmptyStateProps = {
  handleAddStudent: () => void;
};

export function ClassroomStudentsEmptyState({
  handleAddStudent,
}: EmptyStateProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <EmptyClassroom />
      <div>
        <Typography.H4>Esta clase aún no tiene alumnos</Typography.H4>
        <Description>Añade alumnos usando el botón de abajo</Description>
      </div>
      <Button rightIcon="AddUpIcon" onClick={handleAddStudent}>
        {t('students.button.addStudent')}
      </Button>
    </Container>
  );
}
