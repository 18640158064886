import { useCallback, useContext, useEffect, useState } from 'react';
import { User } from 'configcat-common';
import { FeatureFlagsContext } from '../context/FeatureFlags';
import type { FeatureFlagsUser } from '../types/user';

type Props = {
  flag: string;
  defaultValue: any;
  user?: FeatureFlagsUser;
};

function useFeatureFlag({ flag, defaultValue, user }: Props): any {
  const featureFlagClient = useContext(FeatureFlagsContext);

  const [flagValue, setFlagValue] = useState<any>();

  const buildUser = useCallback(() => {
    return user
      ? new User(user.identifier, user?.email, user?.country, user?.custom)
      : undefined;
  }, [user]);

  const fetchFlagValue = useCallback(async () => {
    if (typeof featureFlagClient?.getValueAsync === 'function') {
      const userData = buildUser();
      const value = await featureFlagClient.getValueAsync(
        flag,
        defaultValue,
        userData
      );
      setFlagValue(value);
    }
  }, [buildUser, defaultValue, featureFlagClient, flag]);

  useEffect(() => {
    if (featureFlagClient === undefined) {
      throw new Error(
        'useFeatureFlag must be used within a FeatureFlagProvider'
      );
    }
    fetchFlagValue();
  }, [featureFlagClient, fetchFlagValue]);

  return flagValue;
}

export default useFeatureFlag;
