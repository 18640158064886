import { GridCol, GridContainer } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { Content, Wrapper } from '../components/Layout';

export const StyledWrapper = styled(Wrapper)`
  flex-direction: column;
  table:not(.custom) {
    margin-top: 16px;
    margin-bottom: 20px;
    margin-left: 16px;
    border-collapse: collapse;
    width: 90% !important;
    background-color: ${({ theme }) => theme.colors.white};

    th {
      border: 1px solid ${({ theme }) => theme.colors.dark['04']};
      padding: 16px;
      background: ${({ theme }) => theme.colors.dark['06']};
      text-align: center;
      font-size: 14px !important;
      font-weight: 500;
      line-height: 16px;
    }

    td {
      border: 1px solid ${({ theme }) => theme.colors.dark['04']};
      padding: 8px;
      vertical-align: top;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const StyledGridContainer = styled(GridContainer)`
  width: 100%;
  max-width: 100% !important;
  padding: 0 !important;
`;

export const IntroWidthPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledCol = styled(GridCol)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 80em) {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 0;
  }
`;

export const MaterialsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.dark['06']};
  padding: 24px 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 24px;
  }
`;

export const StyledContent = styled(Content)`
  width: 100%;
  margin-top: 64px;
`;

export const StickyContainer = styled.div`
  width: 100%;
  position: sticky;
  top: calc(var(--heightHeader, 1vh) + 16px);
`;
