import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  actions,
  actionSetAccountData,
  actionSetPlatformUrl,
  actionGetRegionParameters,
  actionSetRegionParameters,
  actionGetAccountPlatformData,
  actionUpdateCoinsAndGems,
  actionSetCoinsAndGems,
  actionSetSolverUrl,
  actionUpdateUnityData,
  actionSkipActivity,
} from './unityData.actions';
import { isRole } from '../auth/selectors';
import { storage } from '@innovamat/radiance-utils';
import platformData from './unityData.dataSource';

import {
  actionRemoveLoading,
  actionSetLoading,
  keys as loadingKeys,
} from '../loading/loading.actions';
import {
  actionCleanErrors,
  actionSetError,
  keys as errorKeys,
} from '../errors/errors.actions';
import { getSessionEntity } from './unityData.selectors';

function* runGetAccountPlatformData({
  user,
}: ReturnType<typeof actionGetAccountPlatformData>): any {
  yield put(actionSetLoading(loadingKeys.SET_ACCOUNT_DATA));
  try {
    const platformUrl: string = yield call(platformData.platformUrl);
    const { isParent, isStudentJunior } = yield select(isRole);
    const { data }: AxiosResponse<any> = yield call(platformData.accounts, {
      platformUrl,
      isParent,
      isStudentJunior,
      studentId: user.uid,
    });

    if (isParent && !data.students.length) {
      storage.clear();
      window.location.replace('/?warning=isUserDisabled');
    }
    if (isParent || isStudentJunior) {
      try {
        const studentId = isParent ? data.students[0].user.uuid : user.uid;
        const biomeResponse = yield call(
          platformData.getBiomes,
          studentId,
          platformUrl
        );
        const biomeId =
          biomeResponse?.data?.biomes.find((biome: any) => biome.isSelected)
            ?.biomeId || 1;
        yield put(actionUpdateUnityData({ biomeId }));
      } catch (e) {
        yield put(actionUpdateUnityData({ biomeId: 1 }));
      }
    }
    yield put(actionSetPlatformUrl(platformUrl));
    yield put(actionSetAccountData(data));
    yield put(actionCleanErrors(errorKeys.SET_ACCOUNT_DATA));
  } catch ({ error, error: response }) {
    window.location.href = '/error';
    yield put(actionSetError(errorKeys.SET_ACCOUNT_DATA, response));
  }
  yield put(actionRemoveLoading(loadingKeys.SET_ACCOUNT_DATA));
}

function* runGetRegionParameters({
  user,
  stage,
}: ReturnType<typeof actionGetRegionParameters>): any {
  yield put(actionSetLoading(loadingKeys.SET_ACCOUNT_DATA));
  try {
    const { data }: AxiosResponse<any> = yield call(
      platformData.regionParameters,
      user,
      stage
    );

    const reginParameters = {
      ...data.regionParameters,
      dataOrigin: 'front-web-webapp',
    };

    yield put(actionSetRegionParameters(reginParameters));
    yield put(actionCleanErrors(errorKeys.SET_ACCOUNT_DATA));
  } catch ({ error, error: response }) {
    yield put(actionSetError(errorKeys.SET_ACCOUNT_DATA, response));
  }
  yield put(actionRemoveLoading(loadingKeys.SET_ACCOUNT_DATA));
}

function* runUpdateCoinsAndGems({
  userId,
  PlatformUrl,
}: ReturnType<typeof actionUpdateCoinsAndGems>): any {
  yield put(actionSetLoading(loadingKeys.SET_ACCOUNT_DATA));
  try {
    const coinsAndGems: { coins: number; gems: number } = yield call(
      platformData.getCoinsAndGems,
      userId,
      PlatformUrl
    );

    const amountGemsLevelUp: AxiosResponse<any> = yield call(
      platformData.getGemsLevelUp,
      PlatformUrl
    );
    const sessionEntity = yield select(getSessionEntity);

    const gems =
      amountGemsLevelUp.data.sessionTranslatorEntity.sessionLevels.find(
        (row: any) => row.session === sessionEntity.session + 1
      );

    yield put(
      actionSetCoinsAndGems({
        ...coinsAndGems,
        gems: gems.gems + coinsAndGems.gems,
      })
    );
    yield put(actionCleanErrors(errorKeys.SET_ACCOUNT_DATA));
  } catch ({ error, error: response }) {
    yield put(actionSetError(errorKeys.SET_ACCOUNT_DATA, response));
  }
  yield put(actionRemoveLoading(loadingKeys.SET_ACCOUNT_DATA));
}

function* runSkipActivity({
  userUuid,
  sessionType,
  codename,
}: ReturnType<typeof actionSkipActivity>): any {
  yield put(actionSetLoading(loadingKeys.SKIP_ACTIVITY));
  try {
    yield call(platformData.skipActivity, userUuid, sessionType, codename);
    yield put(actionCleanErrors(errorKeys.SKIP_ACTIVITY));
  } catch ({ error, error: response }) {
    yield put(actionSetError(errorKeys.SKIP_ACTIVITY, response));
  }
  yield put(actionRemoveLoading(loadingKeys.SKIP_ACTIVITY));
}

export default function* unitySagas(): Generator {
  yield all([
    takeLatest(actions.GET_ACCOUNT_PLATFORM_DATA, runGetAccountPlatformData),
    takeLatest(actions.GET_REGION_PARAMETERS, runGetRegionParameters),
    takeLatest(actions.UPDATE_COINS_GEMS, runUpdateCoinsAndGems),
    takeLatest(actions.SKIP_ACTIVITY, runSkipActivity),
  ]);
}
