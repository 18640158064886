import styled from '@emotion/styled';
import {
  Button,
  IconButton,
  Typography,
  useDevice,
} from '@innovamat/glimmer-components';
import { useMySettings } from '../providers/my-settings-provider';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  border-bottom: 1px solid #e5e5e5;
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 12px 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 8px 16px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 16px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 22;
    background-color: ${({ theme }) =>
      theme.tokens.color.alias.cm.surface['surface-primary'].value};
  }
`;

const Title = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function MySettingHeader(): JSX.Element {
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const { onCancel, onSaveChanges, isSubmitEnabled, isLoading, onGoBack } =
    useMySettings();

  const drawerRoot = document.getElementById('drawer') || document.body;

  const renderActions = (): JSX.Element => {
    return (
      <ActionsWrapper>
        <Actions>
          <Button
            variant="tertiary"
            fill={isMobile}
            onClick={onCancel}
            data-testid="cancelButtonMySettings"
          >
            {t('Settings.Cancel')}
          </Button>
          <Button
            variant="accent"
            fill={isMobile}
            loading={isLoading}
            disabled={isSubmitEnabled}
            onClick={onSaveChanges}
            data-testid="saveButtonMySettings"
          >
            {t('Settings.Guardar')}
          </Button>
        </Actions>
      </ActionsWrapper>
    );
  };

  return (
    <Header>
      <HeaderContent>
        <TitleWrapper>
          <IconButton icon="CloseIcon" onClick={onGoBack} />
          <Title>{t('Modal.Tancar')}</Title>
        </TitleWrapper>
        {isMobile ? createPortal(renderActions(), drawerRoot) : renderActions()}
      </HeaderContent>
    </Header>
  );
}
