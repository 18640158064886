import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Button } from '@innovamat/glimmer-components';

import { Typography, ImageComponent } from '@innovamat/glimmer-components';
import { EmptyClassroom } from '@innovamat/glimmer-assets';

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const StyledSubtitle = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type Props = {
  onAddTeachers: () => void;
};

const EmptyState = ({ onAddTeachers }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <EmptyClassroom />

      <TextContainer>
        <Typography.H4>
          {t(
            'teachers.emptyState.title',
            'Esta clase aún no tiene profesores asignados'
          )}
        </Typography.H4>
        <StyledSubtitle>
          {t(
            'teachers.emptyState.subtitle',
            'Añade profesores usando el botón de abajo'
          )}
        </StyledSubtitle>
      </TextContainer>
      <Button onClick={onAddTeachers}>
        {t('teachers.buttons.addTeachers', 'Añadir profesores')}
      </Button>
    </Container>
  );
};

export { EmptyState };
