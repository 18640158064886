import {
  validators,
  Roles,
  generateRandomString,
} from '@innovamat/radiance-utils';
import { useOnClickOutside } from '@innovamat/hooks';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Student } from '@innovamat/apollo-codegen';
import {
  Input,
  RadioGroup,
  Modal,
  IconButton,
  snack,
} from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { useOrganization } from '../../hooks/use-organization';
import { useConfirmationModal } from '../../hooks/use-confirmation-modal';
import { ConfirmationModal } from '../confirmation-modal';
import {
  GlowApiError,
  useUpdateUserMutation,
} from '@innovamat/glow-api-client';

type UserForm = {
  id: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  language: string;
};

type Props = {
  showModal: boolean;
  onClose: (cancel: boolean) => void;
  initForm: UserForm;
  onSuccess: (id: string, newEmail: string) => void;
  handleInput: (name: keyof Student, value: boolean) => void;
};

type ConfirmationModalContent = {
  title: string;
  message?: string | JSX.Element;
  cancelText?: string;
  confirmText?: string;
};

export type ConfirmationModalKey =
  | 'user.already_exists'
  | 'user_profile.student_junior_already_exists'
  | 'user_profile.student_already_exists'
  | 'user_invitation.convert_parent_to_student_junior';

const CompleteInvitationModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputWrapper = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  align-items: flex-end;

  > div {
    width: inherit;
  }
`;

export function CompleteIndividualAccess({
  showModal,
  onClose,
  initForm,
  onSuccess,
  handleInput,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const [form, setForm] = useState<UserForm>(initForm);
  const [loginMode, setLoginMode] = useState<string | undefined>(undefined);
  const [isEmailInputDisabled, setIsEmailInputDisabled] = useState(true);
  const [isPasswordInputDisabled, setIsPasswordInputDisabled] = useState(true);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const updateUserMutation = useUpdateUserMutation<GlowApiError>();

  const {
    requestConfirmation,
    handleCloseConfirmationModal,
    confirmationModalProps,
    isConfirmationOpen,
  } = useConfirmationModal();

  useEffect(() => setForm(initForm), [initForm]);

  const confirmationModalKeysMap: Record<
    ConfirmationModalKey,
    ConfirmationModalContent
  > = {
    'user.already_exists': {
      title: t('students.existUserModal.title'),
      message: (
        <Trans
          i18nKey={'students.existUserModal.message'}
          values={{ helpEmail: t('common.help-email') }}
          components={{
            a: <a href={`mailto:${t('common.help-email')}`} />,
          }}
        />
      ),
      cancelText: t('confirmModal.action.cancel'),
    },
    'user_profile.student_junior_already_exists': {
      title: t('students.existStudentModal.title'),
      message: t('students.existStudentJuniorModal.message'),
      cancelText: t('confirmModal.action.cancel'),
      confirmText: t('confirmModal.action.assign'),
    },
    'user_profile.student_already_exists': {
      title: t('students.existStudentModal.title'),
      message: t('students.existStudentModal.message'),
      cancelText: t('confirmModal.action.cancel'),
      confirmText: t('confirmModal.action.assign'),
    },
    'user_invitation.convert_parent_to_student_junior': {
      title: t('students.existUserModal.title'),
      message: (
        <Trans
          i18nKey="students.existEmailHomeModal.message"
          components={{
            b: <b />,
          }}
        />
      ),
      cancelText: t('confirmModal.action.cancel'),
      confirmText: t('confirmModal.action.confirm'),
    },
  };

  const handleSend = (forceRoleChange = false): void => {
    handleCloseConfirmationModal();
    const updateUserBody: any = {
      id: form.id,
      firstName: form.firstName,
      lastName: form.lastName,
      password: form.password,
      language: form.language,
      email: form.email,
      roles: [Roles.STUDENT_JUNIOR],
      forceRoleChange,
    };

    const onConfirm = ({
      errorType,
    }: {
      errorType: ConfirmationModalKey;
    }): void => {
      requestConfirmation({
        title:
          confirmationModalKeysMap[errorType]?.title ||
          t('E500.Lo sentimos, ha ocurrido un error'),
        message: confirmationModalKeysMap[errorType]?.message as string,
        cancelText:
          confirmationModalKeysMap[errorType]?.cancelText ||
          t('confirmModal.action.cancel'),
        confirmText: confirmationModalKeysMap[errorType]?.confirmText as string,
        onConfirm: () => handleSend(true),
      });
    };

    updateUserMutation.mutate(
      { body: updateUserBody },
      {
        onError: (error) => {
          const errors = error.response.errors;
          const errorType = errors[0].extensions.exception?.['type'];
          onConfirm?.({ errorType });
        },
        onSuccess: () => {
          onSuccess(form.id, form.email);
          snack.success(t('common.toast.success'));
        },
      }
    );
  };

  const handleClose = (): void => {
    setIsEmailInputDisabled(true);
    setLoginMode(undefined);
    const cancel = true;
    onClose(cancel);
  };

  const handleInputChange = (name: string, value: string): void => {
    setForm((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const isValid = (): boolean => {
    if (showErrorUserNotCreated) return false;

    return (
      !Object.keys(form).some((key) => {
        const element = form[key as keyof typeof form];
        return element === '' || element === undefined;
      }) &&
      !showErrorPwdValid &&
      !showErrorEmailValid
    );
  };

  const showErrorUserNotCreated = Boolean(
    !form.firstName || !form.lastName || !form.id
  );

  const showErrorPwdValid = Boolean(
    form.password && !validators.passwordIsValid(form.password)
  );

  const showErrorEmailValid = Boolean(
    form.email && !validators.isEmailValid(form.email)
  );

  const handleLoginMode = (value: string): void => {
    setLoginMode(value);
    if (value === 'microsoft') {
      const randomPassword = generateRandomString();
      handleInputChange('password', randomPassword);
    }
    if (value === 'emailAndPassword') {
      handleInputChange('password', '');
    }
  };

  useOnClickOutside(emailInputRef, () => {
    setIsEmailInputDisabled(true);
  });
  useOnClickOutside(passwordInputRef, () => {
    setIsPasswordInputDisabled(true);
  });

  useEffect(() => {
    if (!showModal) setLoginMode(undefined);
  }, [showModal]);

  const radioGroupOptions = [
    {
      value: 'microsoft',
      label: t(organization ? 'loginMode.microsoft.us' : 'loginMode.microsoft'),
      groupBy: 'microsoft',
    },
    {
      value: 'emailAndPassword',
      label: t('loginMode.password', 'Contraseña'),
      groupBy: 'emailAndPassword',
    },
  ];

  if (!showModal) return null;

  return (
    <>
      <Modal
        modalWidth={600}
        buttons={[
          {
            children: t('confirmModal.action.complete'),
            onClick: updateUserMutation.isPending
              ? undefined
              : () => handleSend(),
            loading: updateUserMutation.isPending,
            variant: 'accent',
            disabled: !isValid(),
          },
          {
            children: t('confirmModal.action.cancel'),
            onClick: () => handleClose(),
            variant: 'tertiary',
          },
        ]}
        onClose={() => handleClose()}
        isOpen={showModal}
        title={t('students.completeIndividualAccess.title', {
          username: `${form.firstName} ${form.lastName}`,
        })}
      >
        <CompleteInvitationModal>
          <div ref={emailInputRef}>
            <InputWrapper>
              <Input
                value={form.email}
                onChange={(e) => {
                  setIsEmailInputDisabled(false);
                  handleInputChange('email', e.target.value);
                }}
                labelText={t('students.table.labels.email')}
                status={
                  showErrorEmailValid
                    ? 'error'
                    : !!initForm.email && isEmailInputDisabled
                    ? 'autofilled'
                    : undefined
                }
                descriptiveText={
                  showErrorEmailValid ? t('common.email.error') : undefined
                }
              />
              {!!initForm.email && isEmailInputDisabled && (
                <IconButton
                  icon="EditIcon"
                  onClick={() => setIsEmailInputDisabled(false)}
                  tooltipText={t('edit', 'Editar')}
                />
              )}
            </InputWrapper>
          </div>
          <RadioGroup
            title={t('loginMode.title', '¿Cómo va a iniciar sesión?')}
            options={radioGroupOptions}
            groupName="loginMode"
            value={loginMode}
            handleEvent={handleLoginMode}
          />

          {loginMode === 'emailAndPassword' && (
            <>
              <div ref={passwordInputRef}>
                <Input
                  status={
                    showErrorPwdValid
                      ? 'error'
                      : !!initForm.password && isPasswordInputDisabled
                      ? 'autofilled'
                      : undefined
                  }
                  labelText={t('students.table.changePassord.field.password')}
                  descriptiveText={
                    showErrorPwdValid
                      ? t('students.table.changePassord.password.error')
                      : t('students.table.changePassord.password.tagline')
                  }
                  placeholder={t('common.password.placeholder')}
                  type="text"
                  value={form.password}
                  onChange={(e) => {
                    setIsPasswordInputDisabled(false);
                    handleInputChange('password', e.target.value);
                  }}
                  rightIcon={initForm.password !== '' ? 'EditIcon' : undefined}
                  rightIconTooltip={t('edit', 'Editar')}
                  onIconClick={() => setIsPasswordInputDisabled(false)}
                />
              </div>
            </>
          )}
        </CompleteInvitationModal>
      </Modal>
      <ConfirmationModal
        {...confirmationModalProps}
        isOpen={isConfirmationOpen}
        onClose={() => {
          const cancel = true;
          handleCloseConfirmationModal();
          onClose(cancel);
          handleInput('individualAccess', false);
        }}
      />
    </>
  );
}
